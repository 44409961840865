import React, { useCallback, useReducer, useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/system';
import { AxiosError, AxiosResponse } from 'axios';
import mergeWith from 'lodash/mergeWith';
import { QueryFunction, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ErrorModel,
  ExtendedMetadataDataType,
  SearchTaskFilter,
  SearchTaskRequest,
  SearchTaskResultExternal,
  SearchTaskSort,
  SearchTaskStats,
  TaskFilterValuesExternal,
  TaskTypeReadExternal,
} from '@askporter/client-grieg-lyric';
import {
  BasicPageTemplate,
  URLQueryParams,
  parseURLQueryParams,
  LinkedListView,
  Error,
  Pagination,
  useReturnTo,
} from '@askporter/component-library';
import { ExportModalWrapper } from '@askporter/exports';
import { TaskListSideBar, TaskTypeGroupProps } from '@askporter/task-list-sidebar';
import {
  generateTaskSearchFilters,
  mergeArraysWithObjects,
  LinkedTaskMenuOptions,
  MutationResultItem,
  TaskFilterPermissions,
} from '@askporter/utils';
import { API } from '../../api';
import linkedTasksReducer, { initialState } from '../../reducer';
import { LinkedTasksState } from '../../types';
import { generateMenuOptions, transformTaskFilterValues } from '../../utils';
import { LinkedTasksSection } from '../LinkedTasksSection';
import { LinkTasks } from '../LinkTasks';

export interface LinkedTasksProps {
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  linkedListFilter: SearchTaskFilter;
  linkFormFilter: SearchTaskFilter;
  onLink: (tasks: Array<string>) => Promise<void>;
  onDelete: (taskURN: string) => Promise<MutationResultItem<AxiosResponse>>;
  permittedMenuOptions: LinkedTaskMenuOptions[];
  showAddButton?: boolean;
  onCountShouldChange: () => void;
  filterPermissions: TaskFilterPermissions;
  TaskTypeGroup: React.FC<React.PropsWithChildren<TaskTypeGroupProps>>;
  postQueryFn: QueryFunction<SearchTaskResultExternal, [string, Record<string, unknown>]>;
  isExportEnabled?: boolean;
  /**
   * The count for number of linked tasks
   */
  linkedTaskCount: number;
}

/**
 * Renders the linked task form, a list of tasks based on the provided filter. This also includes the link task button
 * and form.
 *
 * @param t - The translation function
 * @param isSmallDevice - small viewport or not
 * @param linkedListFilter - filter for the list of linked tasks
 * @param linkFormFilter - filter passed through to the LinkTasks form
 * @param onLink - function to link tasks to an entity
 * @param onDelete - function to delete a relationship to a task
 * @param showAddButton - defaults to false, if true the add task button will be displayed
 * @param onCountChange - function that is executed after an event that results in the overall linked task count
 * changing
 * @param filterPermissions - object describing the filter visibility
 * @param TaskTypeGroup - component to render the task type group
 * @param postQueryFn - React query POST function for task searches
 *
 */
export const LinkedTasks: React.FC<React.PropsWithChildren<LinkedTasksProps>> = ({
  t,
  isSmallDevice,
  linkedListFilter,
  linkFormFilter,
  onLink,
  onDelete,
  permittedMenuOptions,
  showAddButton = false,
  onCountShouldChange,
  filterPermissions,
  TaskTypeGroup,
  postQueryFn,
  isExportEnabled,
  linkedTaskCount,
}: LinkedTasksProps) => {
  const [linkFormIsVisible, setLinkFormIsVisible] = useState(false);
  const [hasClickedLinkButton, setHasClickedLinkButton] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [{ search, isSearchEnabled }, dispatch] = useReducer(
    linkedTasksReducer,
    initialState(linkedListFilter),
    (state): LinkedTasksState => {
      const { filter = {}, ...rest } = parseURLQueryParams<SearchTaskRequest>(Object.keys(state.search));

      state.search = {
        ...state.search,
        ...rest,
        filter: {
          ...state.search.filter,
          ...filter,
        },
      };
      return state;
    },
  );
  const history = useHistory();
  const location = useLocation();
  const { setReturnToThisPage } = useReturnTo();
  const detailsPageURL = location?.pathname?.substr(0, location?.pathname?.lastIndexOf('/')) || '/app';

  // memoize the filterBody which is provided to react query, only update when filters change
  const memoizedFilterBody = useMemo(() => generateTaskSearchFilters(search.filter), [search.filter]);

  // dispatch wrappers
  const setPage = (page: number) => dispatch({ type: 'SET_PAGE', payload: page });
  const setSearchFreeText = (text: string) => dispatch({ type: 'SET_SEARCH_FREE_TEXT', payload: text });
  const setFilters = (filters: SearchTaskFilter) => {
    dispatch({ type: 'SET_FILTERS', payload: filters });
  };
  const setSort = (sort: SearchTaskSort) => dispatch({ type: 'SET_SORT', payload: sort });
  const setIsSearchEnabled = (enabled: boolean) => dispatch({ type: 'SET_IS_SEARCH_ENABLED', payload: enabled });
  const clearAllFilters = (filter: SearchTaskRequest['filter']) =>
    dispatch({ type: 'CLEAR_ALL_FILTERS', payload: filter });
  const setExtendedMetadataStats = (extendedMetadataStats: Array<string>) =>
    dispatch({ type: 'SET_EXTENDED_METADATA_STATS', payload: extendedMetadataStats });

  const [stats, setStats] = useState<SearchTaskStats>();
  // this needs be passed as a filter to the react query below
  // so the total / global counts can be computed against the linked entity
  const linkedEntityFilterKey = Object.keys(linkedListFilter || {}).find((name) => /linked/.test(name));

  // GET /api/v1/task-filter-values
  const {
    data: taskFilterValuesData,
    isLoading: taskFiltersLoading,
    isSuccess: taskFiltersSuccess,
    isError: taskFilterValuesIsError,
  } = useQuery<TaskFilterValuesExternal>(['task-filter-values'], () => API().get({ path: 'task-filter-values' }), {
    onSuccess: (data) => {
      const extendedMetadataStats = data?.extendedMetadata?.reduce((prev, curr) => {
        if (curr.dataType === ExtendedMetadataDataType.String) return [...prev, curr.qualifiedJsonElementName];

        return prev;
      }, []);

      setExtendedMetadataStats(extendedMetadataStats);
    },
  });

  // POST /api/v1/tasks/search (for obtaining the 'global' stats)
  const {
    isSuccess: unfilteredLinkedTaskSearchSuccess,
    data: unfilteredLinkedTaskSearch,
    isError: unfilteredLinkedTaskSearchError,
  } = useQuery<SearchTaskResultExternal, AxiosError<ErrorModel>>(
    [
      'tasks/search',
      {
        postData: {
          page: 1,
          filter: { [linkedEntityFilterKey]: linkedListFilter[linkedEntityFilterKey as keyof SearchTaskFilter] },
          extendedMetadataStats: search.extendedMetadataStats || [],
        },
        isForFetchingAllStats: true,
      },
    ],
    postQueryFn,
    {
      cacheTime: 500,
      enabled: !!taskFilterValuesData,
    },
  );

  // this query represents what a user see's on the linked tasks page, it is subject to user driven filtering
  const {
    data: filteredLinkedTasks,
    isSuccess: filteredLinkedTasksSuccess,
    refetch: refetchCount,
    isError: isFilteredLinkedTasksError,
    error: filteredLinkedTasksError,
  } = useQuery<{ data: SearchTaskResultExternal }, AxiosError<ErrorModel>>(
    ['tasks/search', { ...search, filter: memoizedFilterBody }],
    () =>
      API().post({
        path: 'tasks/search',
        payload: { ...search, filter: memoizedFilterBody },
        additionalHeaders: { 'x-usecase': 'filtered_search' },
      }),
    {
      keepPreviousData: true,
      enabled: isSearchEnabled && !!taskFilterValuesData,
      onSuccess: () => {
        setIsSearchEnabled(false);
      },
    },
  );

  // merge the stats from the unfiltered request with the filtered one
  useEffect(() => {
    if (
      filteredLinkedTasksSuccess &&
      unfilteredLinkedTaskSearchSuccess &&
      !unfilteredLinkedTaskSearchError &&
      unfilteredLinkedTaskSearch?.stats &&
      filteredLinkedTasks?.data?.stats
    ) {
      setStats(mergeWith({}, unfilteredLinkedTaskSearch.stats, filteredLinkedTasks.data.stats, mergeArraysWithObjects));
    } else if (
      // fallback to 'stats' from the filtered search results
      !unfilteredLinkedTaskSearchSuccess &&
      unfilteredLinkedTaskSearchError &&
      filteredLinkedTasks?.data?.stats &&
      filteredLinkedTasksSuccess
    ) {
      setStats(mergeWith(filteredLinkedTasks.data.stats));
    }
  }, [
    filteredLinkedTasksSuccess,
    unfilteredLinkedTaskSearchSuccess,
    unfilteredLinkedTaskSearchError,
    unfilteredLinkedTaskSearch,
    filteredLinkedTasks,
  ]);

  const refetchData = () => {
    setTimeout(() => {
      onCountShouldChange();
      refetchCount();
    }, 3000);
  };

  // handlers
  const handleDeleteLinkedTask = (taskURN: string) => {
    onDelete(taskURN).then(() => refetchData());
  };
  const handleTasksOnLink = async (tasks: Array<string>): Promise<void> => {
    return onLink(tasks).then(() => {
      refetchData();
    });
  };
  const handlePaginationOnChange = useCallback(
    (_: any, page: number) => {
      setPage(page);
      window.scrollTo(0, 0);
    },
    [dispatch],
  );
  const handleSearchSubmit = (searchBoxValue: string) => {
    if (searchBoxValue !== search?.freeText) {
      setSearchFreeText(searchBoxValue);
      setIsSearchEnabled(true);
    }
  };

  // used to generate the menu options for each task that is listed
  const menuOptions = (taskType: TaskTypeReadExternal, taskUid: string, taskURN: string) =>
    generateMenuOptions({
      permittedMenuOptions,
      isSmallDevice,
      iconPaths: { VIEW_TASK: taskType?.icon?.iconPath },
      handlers: {
        DELETE: () => handleDeleteLinkedTask(taskURN),
        VIEW_TASK: () => {
          setReturnToThisPage();
          history.push(`/app/tasks/${taskUid}`);
        },
      },
      displayNames: {
        DELETE: t('ns.linked_tasks:task_options:remove'),
        VIEW_TASK: t('ns.linked_tasks:task_options:view_task'),
      },
    });

  if (isFilteredLinkedTasksError) {
    return (
      <BasicPageTemplate t={t} isSmallDevice={isSmallDevice} testId="linked-tasks-error">
        <Error
          t={t}
          statusCode={filteredLinkedTasksError?.response?.status}
          errLinkText={t('ns.task:extension:return_link')}
          homeLink={detailsPageURL}
        />
      </BasicPageTemplate>
    );
  }

  return (
    <LinkedListView
      t={t}
      pageTitle={t('ns.linked_tasks:page_title')}
      isSmallDevice={isSmallDevice}
      addButton={
        showAddButton
          ? {
              text: t('linked_tasks:link_task_button'),
              onClick: () => {
                // the first time the button is clicked we set has clicked to true, this mounts the LinkForm component
                if (!hasClickedLinkButton) setHasClickedLinkButton(true);
                setLinkFormIsVisible(true);
              },
            }
          : undefined
      }
      exportButton={
        isExportEnabled && {
          label: t('linked_tasks:export_button:label'),
          isSmallDevice,
          render: ({ handleClose }) => (
            <ExportModalWrapper
              t={t}
              isSmallDevice={isSmallDevice}
              totalSearchResults={linkedTaskCount}
              resourceName="tasks"
              filter={memoizedFilterBody}
              sort={search.sort}
              freeText={search.freeText}
              handleClose={handleClose}
            />
          ),
          disabled: linkedTaskCount === 0,
        }
      }
      searchBox={{
        label: t('ns.common:search:tasks'),
        onSubmit: handleSearchSubmit,
        startValue: search?.freeText,
        searchButtonAria: t('ns.task_list:search_button_aria_label'),
        autocompleteEnabled: true,
        API: API,
        resourceName: 'tasks',
      }}
      filterButton={{ text: t('ns.linked_tasks:filter_button'), onClick: () => setShowSidebar(true) }}
      noResults={{
        isVisible: filteredLinkedTasks?.data?.results?.length === 0 ? true : false,
        message: t('ns.linked_tasks:no_linked_tasks'),
      }}
      sections={[
        {
          key: 'linked_tasks_section',
          children: (
            <LinkedTasksSection
              tasks={filteredLinkedTasks?.data?.results}
              generateMenuOptions={menuOptions}
              menuTitle={t('ns.linked_tasks:task_options:menu_title')}
              isSmallDevice={isSmallDevice}
            />
          ),
        },
      ]}
    >
      <>
        <URLQueryParams state={{ ...search, filter: memoizedFilterBody }} />
        {/* If showAddButton is true and the user has clicked the new task button for the firs time the Link Tasks
        form will be rendered, the visibility is controlled by state  */}
        {showAddButton && hasClickedLinkButton && (
          <LinkTasks
            isVisible={linkFormIsVisible}
            setVisibility={(visibility: boolean) => setLinkFormIsVisible(visibility)}
            onLink={handleTasksOnLink}
            t={t}
            isSmallDevice={isSmallDevice}
            filter={linkFormFilter}
          />
        )}
        <Box position="absolute" left={0}>
          <TaskListSideBar
            TaskTypeGroup={TaskTypeGroup}
            stats={stats}
            filters={search.filter}
            taskFilterValues={{
              priority: transformTaskFilterValues({
                values: taskFilterValuesData?.priorities,
                stats: stats?.priorities,
                groupByDisplayName: true,
              }),
              status: transformTaskFilterValues({
                values: taskFilterValuesData?.statuses,
                stats: stats?.statuses,
                groupByDisplayName: true,
              }),
            }}
            errors={{ taskFilterValuesIsError }}
            isLoading={taskFiltersLoading || !taskFiltersSuccess}
            t={t}
            isSmallDevice={isSmallDevice}
            actions={{
              clearAllFilters: () => clearAllFilters(linkedListFilter),
              setIsSearchEnabled,
              setFilters,
              setSort,
            }}
            filterPermissions={filterPermissions}
            sort={search.sort}
            sideBarDrawerMode={{
              show: showSidebar,
              onClose: () => setShowSidebar(false),
            }}
            extendedMetadataFilters={taskFilterValuesData?.extendedMetadata}
          />
        </Box>

        {filteredLinkedTasks?.data?.results?.length > 0 ? (
          <Pagination
            page={search.page}
            count={filteredLinkedTasks?.data?.summary?.totalPages || 0}
            limitCount={499}
            onChange={handlePaginationOnChange}
          />
        ) : null}
      </>
    </LinkedListView>
  );
};
