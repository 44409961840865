import { API } from '../../utils/api';
import { getFileNameFromUrl } from './getFileNameFromUrl';

export default async (uid: string): Promise<{ data: Blob; fileName: string }> => {
  // A change was made to api-client/src/APIClient/index.ts, so the responseURL is returned only for export downloads
  const { data, responseURL } = await API().get({ path: `exports/${uid}/download`, responseType: 'blob' });

  const fileName = getFileNameFromUrl(responseURL);

  return { data, fileName };
};
