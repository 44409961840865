import { CallDirection, CallDisconnectedBy, CallRecordSearchResultExternal } from '@askporter/client-grieg-lyric';
import { fullName } from '../..';
import { getMainAgent } from './getMainAgent';

/**
 * Map of strings for the disconnected by column, this is used to determine the correct string to display
 * given the direction of the call and the disconnected by value
 */
const disconnectedByStrings: Record<CallDisconnectedBy, Record<CallDirection, string>> = {
  CALLER: { INBOUND: 'CUSTOMER', OUTBOUND: 'AGENT', INTERNAL: 'UNKNOWN' },
  CALLEE: { INBOUND: 'AGENT', OUTBOUND: 'CUSTOMER', INTERNAL: 'UNKNOWN' },
  UNKNOWN: { INBOUND: 'UNKNOWN', OUTBOUND: 'UNKNOWN', INTERNAL: 'UNKNOWN' },
};

/**
 * Function for obtaining the correct text for the disconnected by column, this could be either a named individual
 * or a generic string such as "Customer" or "Agent"
 */
export const getDisconnectedBy = (
  callRecord: CallRecordSearchResultExternal,
  t: (key: string) => string,
): { displayName: string; type: 'CUSTOMER' | 'AGENT' | 'UNKNOWN'; profilePhotoPath: string } => {
  const { customerCall, direction = 'INBOUND' } = callRecord;
  const callDisconnectedBy = customerCall?.disconnectedBy || 'UNKNOWN';
  const disconnectedBy = disconnectedByStrings[callDisconnectedBy][direction];

  if (disconnectedBy === 'CUSTOMER') {
    const customerFullName = fullName(callRecord?.customer?.givenName || '', callRecord?.customer?.familyName || '');

    return {
      displayName: customerFullName || t('call_records:table:disconnected_by:customer'),
      type: 'CUSTOMER',
      profilePhotoPath: callRecord?.customer?.profilePhoto?.filePath || '',
    };
  } else if (disconnectedBy === 'AGENT') {
    const agent = getMainAgent(callRecord?.agents);
    const agentFullName = fullName(agent?.givenName || '', agent?.familyName || '');

    return {
      displayName: agentFullName || t('call_records:table:disconnected_by:agent'),
      type: 'AGENT',
      profilePhotoPath: agent?.profilePhoto?.filePath || '',
    };
  }

  return { displayName: t('call_records:table:disconnected_by:unknown'), type: 'UNKNOWN', profilePhotoPath: '' };
};
