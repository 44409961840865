import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AssetReadExternalExternalLink } from '@askporter/client-grieg-lyric';
import { Button, Icon, IconSize } from '../../';

export interface HeaderExternalLinkButtonProps {
  externalLink: AssetReadExternalExternalLink;
}

/** Renders a HeaderExternalLinkButton component */
export const HeaderExternalLinkButton: React.FC<React.PropsWithChildren<HeaderExternalLinkButtonProps>> = ({
  externalLink,
}: HeaderExternalLinkButtonProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  if (!externalLink || !externalLink?.linkUrl || !externalLink?.linkTitle) return null;

  return (
    <Button
      rel="external"
      variant="outlined"
      sx={{ minWidth: isSmallDevice ? '100%' : 'unset' }}
      target="_blank"
      href={externalLink?.linkUrl}
    >
      <Box component="span" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {externalLink?.linkTitle}
      </Box>
      <Box ml={2}>
        <Icon size={IconSize.MD} folder="actions/11-new-page" fillColor={theme.palette.primary.main} />
      </Box>
    </Button>
  );
};
