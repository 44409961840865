import React from 'react';
import { Box, Link } from '@mui/material';
import { Typography, CustomTypographyProps, CustomTypographyVariant } from '../..';

export interface BreadcrumbProps {
  /**
   * The text to display in the breadcrumb
   */
  text?: string;
  /**
   * The href the breadcrumb should link to
   */
  href?: string;
  /*
   * The icon to display before the breadcrumb text within the a tag
   */
  backIcon?: JSX.Element | JSX.Element[];
  /**
   * The adornment to display after the breadcrumb text
   */
  endAdornment?: JSX.Element | JSX.Element[];
  /**
   * The typography variant, can be supplied at breadcrumb level or at breadcrumbs level, breadcrumb level takes precedence
   */
  variant?: CustomTypographyVariant;
  /**
   * The typography color, can be supplied at breadcrumb level or at breadcrumbs level, breadcrumb level takes precedence
   */
  color?: CustomTypographyProps['color'];
}

/**
 * Renders a single AskPorter Breadcrumb
 */
export const Breadcrumb: React.FC<React.PropsWithChildren<BreadcrumbProps>> = ({
  text,
  href,
  backIcon,
  endAdornment,
  variant = 'body1',
  color = 'textPrimary',
}: BreadcrumbProps) => {
  const flexCentered = { display: 'flex', alignItems: 'center' };

  return (
    <Box sx={{ display: 'flex' }}>
      {href ? (
        <Link component="a" href={href} sx={flexCentered} variant={variant} color={color}>
          {!!backIcon && <Box sx={{ ...flexCentered, width: '20px', height: '20px', mr: 2 }}>{backIcon}</Box>}
          {text}
        </Link>
      ) : (
        <Box sx={flexCentered}>
          {!!backIcon && <Box sx={{ ...flexCentered, width: '20px', height: '20px', mr: 2 }}>{backIcon}</Box>}
          <Typography variant={variant} color={color}>
            {text}
          </Typography>
        </Box>
      )}
      {!!endAdornment && (
        <Box data-testid="end-adornment-container" sx={{ ...flexCentered, ml: 2 }}>
          {endAdornment}
        </Box>
      )}
    </Box>
  );
};
