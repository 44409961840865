import React, { useState } from 'react';
import { Close as CloseIcon, GetApp as GetAppIcon } from '@mui/icons-material';
import { Box, Modal, IconButton } from '@mui/material';
import { FilePath } from '@askporter/client-grieg-lyric';
import { Button } from '../../';

interface FilePreviewProps {
  isSmallDevice?: boolean;
  handleClose: () => void;
  file: FilePath;
  title?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
}

export const FilePreview = ({ isSmallDevice, handleClose, file, title, t }: FilePreviewProps): JSX.Element => {
  const [full, setFull] = useState(false);

  const handleImageClick = () => {
    if (!isSmallDevice) setFull(!full);
  };

  return (
    <Modal open onClose={handleClose} data-testid="file-preview">
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          width: '100%',
          background: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
          overflowY: 'scroll',
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#212121',
            height: '80px',
            padding: 4,
          }}
        >
          <Box>
            <IconButton
              onClick={handleClose}
              aria-label={t('ns.common:modal:close_button:aria_label')}
              color="secondary"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box position="absolute" top="16px" right="16px">
            {isSmallDevice ? (
              <IconButton
                aria-label={`${t('ns.task:media:download_button')}`}
                color="secondary"
                component="a"
                href={file?.filePath}
              >
                <GetAppIcon />
              </IconButton>
            ) : (
              <Button
                data-testid={`download-button`}
                variant="contained"
                color="secondary"
                endIcon={<GetAppIcon />}
                component="a"
                href={file?.filePath}
              >
                {t('ns.task:media:download_button')}
              </Button>
            )}
          </Box>
        </Box>

        <Box onClick={handleImageClick} onKeyPress={handleImageClick} role="button" sx={{ height: '100%' }}>
          <img
            src={file.filePath}
            alt={title}
            style={
              full ? { cursor: 'zoom-out' } : { cursor: 'zoom-in', height: '100%', width: '100%', objectFit: 'contain' }
            }
          />
        </Box>
      </Box>
    </Modal>
  );
};
