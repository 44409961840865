import React from 'react';
import { Box, IconButton } from '@mui/material';
import Icon, { IconSize } from '../../../../Icon';
import { Typography } from '../../../../Typography';

interface Props {
  title: string;
  rangeText: string;
  disabled?: boolean;
  editOnClick?: () => void;
  editAriaLabel?: string;
}

export const RangeRow: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  rangeText,
  disabled,
  editOnClick,
  editAriaLabel,
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '44px' }}>
      <Box>
        <Typography variant="body2">{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">{rangeText}</Typography>
        {!disabled && (
          <IconButton onClick={editOnClick} aria-label={editAriaLabel} sx={{ ml: 4.62 }}>
            <Icon folder="actions/24-edit" size={IconSize.MD} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
