import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { DayOfWeek } from '@askporter/client-grieg-lyric';
import { DateAndTimePicker, DateAndTimePickerProps } from '../../../../../';
import { LoadingButton } from '../../../../Buttons/LoadingButton';
import { RoundButton, RoundButtonSize } from '../../../../Buttons/RoundButton';
import { Dialog } from '../../../../Dialog';
import { DayTimeType } from '../../types';
import { defaultDays, days, getDays, mergeDayTimeState, filterNoServiceTimeDays, parseDayTime } from '../../utils';

export interface SelectDayTimeProps {
  title?: string;
  dataTestId?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  isLoading?: boolean;
  isVisible: boolean;
  handleClose: () => void;
  setIsModalVisible: (isModalVisible: boolean) => void;
  isSmallDevice: boolean;
  selectedDays?: Record<string, boolean>;
  setSelectedDays: (selectedDays: Record<string, boolean>) => void;
  onAdd: (value: Array<DayTimeType>) => void;
  value: Array<DayTimeType>;
  setTimeValue: (timeValue: Date | null) => void;
  timeValue: Date | null;
  noServiceCheckbox: boolean;
  setNoServiceCheckbox: (noServiceCheckbox: boolean) => void;
  addDayTime: (dayTimes: Record<DayOfWeek, string>) => void;
  dayTimes: Record<DayOfWeek, string>;
}

/**
 * Allows the user to set a time for a week day
 */
export const SelectDayTimeModal: React.FC<React.PropsWithChildren<SelectDayTimeProps>> = ({
  t,
  title,
  dataTestId,
  isLoading,
  isVisible,
  setIsModalVisible,
  handleClose,
  isSmallDevice,
  selectedDays = defaultDays,
  setSelectedDays,
  onAdd,
  value,
  setTimeValue,
  timeValue,
  noServiceCheckbox,
  setNoServiceCheckbox,
  addDayTime,
  dayTimes,
}: SelectDayTimeProps) => {
  const localeProps: DateAndTimePickerProps['localeProps'] = {
    okText: t('common:date_picker:ok'),
    clearText: t('common:date_picker:clear'),
    cancelText: t('common:date_picker:cancel'),
    toggleVisibilityText: t('common:date_picker:toggle_visibility'),
  };

  const _onChange = (time: string | null) => {
    setTimeValue(time ? new Date(time) : null);
  };

  const _onAdd = () => {
    const v = [{ days: getDays(selectedDays), time: timeValue ? timeValue.toTimeString().substring(0, 5) : null }];
    const payload = filterNoServiceTimeDays({ ...dayTimes, ...parseDayTime(v) });

    const newState = [
      { days: getDays(selectedDays), time: timeValue ? timeValue.toTimeString().substring(0, 5) : null },
    ];
    const mergedState = mergeDayTimeState(value || [], newState);

    onAdd(mergedState);
    addDayTime(payload);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoServiceCheckbox(e.target.checked);
    _onChange(null);
  };

  return (
    <Dialog
      title={title}
      isOpen={isVisible}
      isSmallDevice={isSmallDevice}
      onClose={handleClose}
      containerWidth="544px"
      actions={
        <>
          <Button onClick={() => setIsModalVisible(false)}>{t('common:cancel')}</Button>
          <LoadingButton
            onClick={() => _onAdd()}
            isLoading={isLoading}
            type="submit"
            disabled={!timeValue && !noServiceCheckbox}
          >
            {t('common:add')}
          </LoadingButton>
        </>
      }
    >
      <Box data-testid={dataTestId} sx={{ display: 'flex', gap: isSmallDevice ? 3 : 6.5 }}>
        {Object.keys(days).map((day, i) => (
          <RoundButton
            onClick={() => setSelectedDays({ ...selectedDays, [i]: !selectedDays[i] })}
            isSelected={selectedDays[i] === true}
            size={isSmallDevice ? RoundButtonSize.SM : RoundButtonSize.LG}
            key={`${day}-${i}`}
            aria-label={`${t(`common:day_of_week:${day}`)} - ${t(
              `day_time:edit:${selectedDays[i] === true ? 'selected' : 'unselected'}`,
            )}`}
          >
            {t(`common:day_of_week:${day}`)?.substring(0, 1)}
          </RoundButton>
        ))}
      </Box>
      <Box sx={{ mt: 6 }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={noServiceCheckbox} onChange={handleCheckboxChange} />}
            label={t('day_time:select_day_time:no_service')}
          />
          {!noServiceCheckbox && (
            <DateAndTimePicker
              value={timeValue}
              onChange={_onChange}
              isSmallDevice={isSmallDevice}
              localeProps={localeProps}
              controls={['time']}
              timeFormat="hh:mm A"
              inputProps={{
                label: t('day_time:select_day_time:time'),
                inputProps: {
                  'data-testid': 'time-picker',
                  key: new Date(timeValue).getTime(),
                },
              }}
            />
          )}
        </FormGroup>
      </Box>
    </Dialog>
  );
};
