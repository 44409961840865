import { SearchTaskRequest } from '@askporter/client-grieg-lyric';
import { LinkTasksState, LinkTasksActions } from '../types';

export const initialState = (filter?: SearchTaskRequest['filter']): LinkTasksState => ({
  search: {
    freeText: '',
    page: 1,
    filter,
  },
  selectedItems: [],
  isProcessing: false,
});

const linkTasksReducer = (state: LinkTasksState, action: LinkTasksActions): LinkTasksState => {
  switch (action.type) {
    case 'SET_SEARCH_FREE_TEXT':
      return { ...state, search: { ...state.search, freeText: action.payload, page: 1 } };
    case 'SET_PAGE':
      return { ...state, search: { ...state.search, page: action.payload } };
    case 'SET_SELECTED_ITEMS':
      return { ...state, selectedItems: action.payload };
    case 'CLEAR_SELECTION':
      return { ...state, selectedItems: [] };
    case 'SET_IS_PROCESSING':
      return { ...state, isProcessing: action.payload };
    default:
      return state;
  }
};

export default linkTasksReducer;
