import {
  AssetExportReadExternal,
  OrgExportReadExternal,
  TaskExportReadExternal,
  TeamExportReadExternal,
  UserExportReadExternal,
} from '@askporter/client-grieg-lyric';
import { API } from './api';

export type FetchExportByUidData =
  | TaskExportReadExternal
  | AssetExportReadExternal
  | UserExportReadExternal
  | OrgExportReadExternal
  | TeamExportReadExternal;

export default async (uid: string): Promise<FetchExportByUidData> => {
  const data = await API().get({ path: `exports/${uid}` });
  return data;
};
