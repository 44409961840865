import { DayOfWeek } from '@askporter/client-grieg-lyric';

export const days = {
  [DayOfWeek.Mon]: 0,
  [DayOfWeek.Tue]: 1,
  [DayOfWeek.Wed]: 2,
  [DayOfWeek.Thu]: 3,
  [DayOfWeek.Fri]: 4,
  [DayOfWeek.Sat]: 5,
  [DayOfWeek.Sun]: 6,
};

export const daysByIndex: Record<string, DayOfWeek> = {
  0: DayOfWeek.Mon,
  1: DayOfWeek.Tue,
  2: DayOfWeek.Wed,
  3: DayOfWeek.Thu,
  4: DayOfWeek.Fri,
  5: DayOfWeek.Sat,
  6: DayOfWeek.Sun,
};

export const defaultDays = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

export const selectAllDays = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
};

// returns an array of days of the week from an object of selected days e.g.
//
// {
//  0: true,
//  1: true,
//  2: false,
//  3: false,
//  4: false,
//  5: false,
//  6: false,
// } => ['MON', 'TUE']
export const getDays = (selectedWeekDays: Record<string, boolean>): DayOfWeek[] =>
  Object.keys(selectedWeekDays || {})
    .filter((k) => selectedWeekDays[k] === true)
    .map((d) => daysByIndex[d]);
