import React from 'react';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Typography, Pagination, SearchBox, SearchBoxProps } from '../../';
import { PaginationInterface } from '../../types/Pagination';

export interface ListViewProps {
  /**
   * the h1 for the list view page
   */
  title?: string;
  /**
   * optional, used as h1 instead of title
   */
  accessibleTitle?: string;
  /**
   * optional, shows a Tabs component if passed e.g. for People
   */
  tabs?: () => React.ReactNode;
  /**
   * optional, if not provided the search box will not be displayed
   */
  searchBox?: SearchBoxProps;
  /**
   * optional, if not provided the select link will not be displayed
   */
  selectOnClick?: (event?: React.SyntheticEvent) => void;
  /**
   * Whether we are in single / split column
   */
  isSmallDevice: boolean;
  /**
   * the JSX items you want to display in the list view, for example a list of cards
   */
  children: Array<React.ReactNode> | React.ReactNode;
  /**
   * pagination configuration object { page, pageCount, onChange }
   */
  pagination: PaginationInterface;
}

/**
 * Renders a ListView component with search bar and sort drop down,
 * also controls spacing for cards which are passed in as children
 */
export const ListView: React.FC<React.PropsWithChildren<ListViewProps>> = ({
  title,
  accessibleTitle,
  tabs,
  searchBox,
  children,
  isSmallDevice,
  pagination,
}: ListViewProps) => {
  const searchBoxProps = searchBox;
  return (
    <Box data-testid="list-view" sx={{ mb: isSmallDevice ? 24 : 0 }}>
      {(accessibleTitle || title) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="nowrap"
          mb={isSmallDevice ? 0 : 6}
          mt={isSmallDevice ? 4 : 0}
        >
          <Typography
            variant="h3"
            component="h1"
            paddingX={isSmallDevice ? 4 : 0}
            sx={accessibleTitle && !title && { ...visuallyHidden }}
            data-testid="list-view-title"
          >
            {accessibleTitle || title}
          </Typography>
        </Box>
      )}

      {tabs && <Box sx={{ mb: 4, px: isSmallDevice ? 4 : 0 }}>{tabs()}</Box>}

      {searchBox && (
        <Box sx={{ mb: 4 }}>
          <SearchBox {...searchBoxProps} disableSelfSpacing={true} />
        </Box>
      )}

      <Box sx={{ display: 'grid', gridGap: 8 }}>{children}</Box>

      {pagination && (
        <Pagination
          data-testid="list-view-pagination"
          page={pagination.page}
          count={pagination.pageCount}
          limitCount={499}
          onChange={pagination.onChange}
          sx={{ mt: 4 }}
        />
      )}
    </Box>
  );
};
