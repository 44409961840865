import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '../../../Typography';

interface SectionProps {
  title?: string;
  testId?: string;
  children: JSX.Element | JSX.Element[];
}

/**
 * Renders a section on a Linked List page
 */
const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({ title, testId, children }: SectionProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      marginBottom={8}
      data-testid={testId ? testId : 'linked-list-section'}
    >
      {title ? <Typography variant="h3">{title}</Typography> : null}
      {children}
    </Box>
  );
};

export default Section;
