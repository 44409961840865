import {
  CallRecordReadFullExternal,
  CallRecordSearchResultExternal,
  UserRefHydrated,
} from '@askporter/client-grieg-lyric';
import { formatDate, fullName, FullUserRole } from '../..';
import { FormattedDuration } from '../../types/FormattedDuration';
import { getDisconnectedBy, getMainAgent, getDurationInMs, getFormattedDurationObject } from '.';

interface AgentWorkerUser extends UserRefHydrated {
  fullName: string;
}
interface CallRecordDetailsObject {
  agent: UserRefHydrated;
  agentLine: string;
  agentName: string;
  brandName: string;
  customer: UserRefHydrated;
  customerLine: string;
  customerFullName: string | undefined;
  customerRole: string;
  completedAt: string;
  disconnectedBy: { displayName: string; type: 'CUSTOMER' | 'AGENT' | 'UNKNOWN'; profilePhotoPath: string };
  from: string;
  getAgentByContactUri: (contactUri: string) => AgentWorkerUser | undefined;
  getAgentByWorkerSid: (workerSid: string) => AgentWorkerUser | undefined;
  queueName: string;
  to: string;
  date: string;
  startedAt: string;
  endedAt: string;
  noOfRecordings: number;
  durationInMs: number | '';
  formattedDuration: Record<string, never> | FormattedDuration;
}

/**
 * Function for obtaining call record details from a CallRecordSearchResultExternal. This applies logic to determine the
 * correct values given the direction of the call, as well as extracting the main agent.
 *
 */
export const getCallRecordDetails = (
  callRecord: CallRecordSearchResultExternal | CallRecordReadFullExternal,
  t: (key: string) => string,
): CallRecordDetailsObject => {
  const brandName = callRecord?.brand?.name || '';
  const queueName = callRecord?.latestQueue?.queueName || '';

  const customerLine = callRecord?.customerCall?.customerLine || '';
  const agentLine = callRecord?.twilioLine || '';

  const from = callRecord?.direction === 'INBOUND' ? customerLine : agentLine;
  const to = callRecord?.direction === 'INBOUND' ? agentLine : customerLine;

  const agent = getMainAgent(callRecord?.agents);
  const agentName = agent ? fullName(agent?.givenName || '', agent?.familyName || '') : '';

  const getAgentByWorkerSid = (workerSid: string) => {
    const agent = callRecord.agents?.find((agent) => agent.workerSid === workerSid);
    return {
      ...agent?.user,
      fullName: agent?.user ? fullName(agent.user.givenName || '', agent.user.familyName || '') : '',
    };
  };

  const getAgentByContactUri = (contactUri: string) => {
    const agent = callRecord.agents?.find((agent) => agent.contactUri === contactUri);
    return {
      ...agent?.user,
      fullName: agent?.user ? fullName(agent.user.givenName || '', agent.user.familyName || '') : '',
    };
  };

  const disconnectedBy = getDisconnectedBy(callRecord, t);

  const date = callRecord?.startAt ? formatDate(callRecord?.startAt, 'dd MMM yyyy') || '' : '';
  const startedAt = callRecord?.startAt ? formatDate(callRecord?.startAt, 'HH:mm') || '' : '';
  const endedAt = callRecord?.endAt ? formatDate(callRecord?.endAt, 'HH:mm') || '' : '';
  const completedAt = callRecord?.completedAt ? formatDate(callRecord?.completedAt, 'HH:mm') || '' : '';

  const durationInMs =
    callRecord?.timings?.totalDuration != null
      ? callRecord?.timings?.totalDuration
      : getDurationInMs(callRecord?.startAt, callRecord?.endAt);

  const formattedDuration = durationInMs && getFormattedDurationObject(durationInMs);

  const noOfRecordings = callRecord?.recordings?.length || 0;

  const customer = callRecord?.customer;
  const customerFullName = fullName(callRecord?.customer?.givenName ?? '', callRecord?.customer?.familyName ?? '');
  const customerRole = `${callRecord?.customer?.roleSlug && t(FullUserRole[callRecord?.customer?.roleSlug])}`;

  return {
    agent,
    agentLine,
    agentName,
    brandName,
    customer,
    customerLine,
    customerFullName,
    customerRole,
    completedAt,
    disconnectedBy,
    from,
    getAgentByContactUri,
    getAgentByWorkerSid,
    queueName,
    to,
    date,
    startedAt,
    endedAt,
    noOfRecordings,
    durationInMs,
    formattedDuration,
  };
};
