import React, { ChangeEvent, useState } from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { Typography } from '../Typography';
import { isRadioButtonChecked } from './utils';

interface SearchSortProps {
  t: (key: string) => string;
  items: Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
  initialValue: string;
}

const SearchSort = ({ t, items, onChange, initialValue }: SearchSortProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setSelectedValue(event.target.value);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <FormControl component="fieldset" sx={{ m: 0 }}>
        <Typography component="legend" variant="h3">
          {t('ns.common:sort_by_label')}
        </Typography>
        <RadioGroup
          defaultValue={initialValue}
          aria-label={t('ns.common:sort_by_label')}
          name="sort-options"
          onChange={handleChange}
          value={selectedValue}
        >
          {items.map(({ label, value }) => {
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={<Typography variant="body1">{label}</Typography>}
                data-testid={`sort-by-option--${value}`}
                checked={isRadioButtonChecked(selectedValue || initialValue, value)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export { SearchSort };
