import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Permissions } from '@askporter/auth-provider';
import { TaskExportCreate, TaskExportCreateFieldsEnum } from '@askporter/client-grieg-lyric';
import { exportTasksCheckboxFieldsPermissionMapping } from '../../../utils/exportCheckboxFieldPermissionMapping';
import { ExportFieldCheckbox } from '../../ExportFieldCheckbox';

interface ExportTasksFieldsProps {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The form control
   */
  control: Control<TaskExportCreate>;
  // TODO: Weird error(Passing TaskExportModalFormControlType from ../../../types causes a resolver error)
}

/**
 * Renders the checkboxes for the tasks export modal based on the user's role
 */
export const ExportTasksFields: React.FC<ExportTasksFieldsProps> = ({ t, control }) => {
  const nonEssentialFields = Object.values(TaskExportCreateFieldsEnum);

  return (
    <>
      <Permissions requiredRoles={exportTasksCheckboxFieldsPermissionMapping.TASK_ESSENTIALS}>
        <ExportFieldCheckbox
          fieldId="TASK_ESSENTIALS"
          disabled={true}
          label={t('tasks:export_modal:task_essentials:checkbox:label')}
          subLabel={t('tasks:export_modal:task_essentials:checkbox:subLabel')}
          isChecked={true}
        />
      </Permissions>

      {nonEssentialFields.map((fieldId, index) => (
        <Permissions key={index} requiredRoles={exportTasksCheckboxFieldsPermissionMapping[fieldId]}>
          <Controller
            name="fields"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <ExportFieldCheckbox
                fieldId={fieldId}
                label={t(`tasks:export_modal:${fieldId.toLowerCase()}:checkbox:label`)}
                subLabel={t(`tasks:export_modal:${fieldId.toLowerCase()}:checkbox:subLabel`)}
                isChecked={field.value.includes(fieldId)}
                onChange={(checked) =>
                  field.onChange(checked ? [...field.value, fieldId] : field.value.filter((value) => value !== fieldId))
                }
                disabled={field.disabled}
              />
            )}
          />
        </Permissions>
      ))}
    </>
  );
};
