import { ExtendedMetadataDataType } from '@askporter/client-grieg-lyric';
import DayTimeField from '../DayTimeField';
import DayTimeRangeField from '../DayTimeRangeField';
import LabelField from '../LabelField';
import MultiFileUploadField from '../MultiFileUploadField';
import MultiLangField from '../MultiLangField';
import SingleFileUploadField from '../SingleFileUploadField';

export const dataTypeTransformMap: Record<string, Record<string, unknown>> = {
  [ExtendedMetadataDataType.Multilinestring]: {
    'ui:widget': 'textarea',
    'ui:extendedMetadataType': ExtendedMetadataDataType.Multilinestring,
  },
  [ExtendedMetadataDataType.Multilangstring]: {
    'ui:field': MultiLangField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.Multilangstring,
  },
  [ExtendedMetadataDataType.Daytime]: {
    'ui:field': DayTimeField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.Daytime,
  },
  [ExtendedMetadataDataType.Daytimerange]: {
    'ui:field': DayTimeRangeField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.Daytimerange,
  },
  [ExtendedMetadataDataType.Multifile]: {
    'ui:field': MultiFileUploadField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.Multifile,
  },
  [ExtendedMetadataDataType.File]: {
    'ui:field': SingleFileUploadField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.File,
  },
  [ExtendedMetadataDataType.Label]: {
    'ui:field': LabelField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.Label,
  },
};
