import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '../Typography';

export interface ListPageHeaderProps {
  /**
   * A return button component
   */
  returnButton?: React.ReactNode;
  /**
   * the page title for example "The best list"
   */
  title: string | React.ReactNode;
  /**
   * the secondary text in a title, usually a count for example "(92)"
   */
  secondaryTitle?: string;
  /**
   * description text, displayed below the title
   */
  description?: string;
  /**
   * a react element that is aligned to the right of the title
   */
  rightElement?: React.ReactNode;
}

/** Renders a ListPageHeader component */
export const ListPageHeader: React.FC<React.PropsWithChildren<ListPageHeaderProps>> = ({
  title,
  secondaryTitle,
  description,
  rightElement,
  returnButton,
}: ListPageHeaderProps) => {
  return (
    <Box p={6} pt={returnButton ? 8 : undefined}>
      {returnButton ? returnButton : null}
      <Box display="flex" justifyContent="space-between">
        <Box width="70%">
          <Box display="flex" gap={2} alignItems="center">
            {typeof title === 'string' || title instanceof String ? (
              <Typography variant="h3">{title}</Typography>
            ) : (
              title
            )}
            {secondaryTitle ? (
              <Typography variant="caption" ml="2">
                {secondaryTitle}
              </Typography>
            ) : null}
          </Box>
          {description ? (
            <Typography variant="caption" color="text.secondary">
              {description}
            </Typography>
          ) : null}
        </Box>
        {rightElement}
      </Box>
    </Box>
  );
};
