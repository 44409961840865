import { DayOfWeek } from '@askporter/client-grieg-lyric';
import { DayTimeType } from '../types';

export const parseDayTime = (dayTimeArray: Array<DayTimeType>): Record<DayOfWeek, string> => {
  const result: Record<string, string> = {};

  (dayTimeArray || []).map((time) => {
    time?.days?.forEach((day) => {
      if (time?.time) {
        const formattedTime = time.time?.split(':');
        result[day] = `${formattedTime[0]}:${formattedTime[1]}`;
      } else {
        result[day] = '';
      }
    });
  });

  return result;
};
