import { TenantLanguage } from '@askporter/client-grieg-lyric';

/**
 * Check if string has only upper case letters
 *
 * @param str - string to check
 * @returns boolean
 */
export const isUpper = (str: string): boolean => {
  return !/[a-z]/.test(str) && /[A-Z]/.test(str);
};

/**
 * Initialise the user language, first by attempting to grab it from local storage - failing that it is obtained from
 * the tenant configuration, if both these fail the fallback language is returned
 *
 * @param tenantLanguages
 * @param fallbackLanguage
 * @returns a string containing the language code
 */
const initUserLanguage = (tenantLanguages: Array<TenantLanguage>, fallbackLanguage: string): string => {
  // If the user already has a language stored in local storage then use that
  // also check that they don't have the legacy format e.g. en-gb
  // if so then we should re-initialise
  try {
    const selectedLang = localStorage.getItem('selectedLanguage');

    if (selectedLang) {
      const country = selectedLang.split('-');
      if (country[1] && isUpper(country[1])) {
        return selectedLang;
      }
    }
  } catch (err) {
    // if localStorage not available then carry on
  }

  // Check if tenantLanguages exist, if so filter the array to only contain the default language else assign an empty
  // array
  const tenantDefaultLang = tenantLanguages ? tenantLanguages.filter((lang) => lang.defaultLanguage) : [];

  // To handle an instance where there is more than one language marked as default, we only select the first item. If
  // no items exist then return the fall back language
  const language = tenantDefaultLang.length > 0 ? tenantDefaultLang[0].languageCode : fallbackLanguage;

  // As the user didn't have the language stored in local storage, set it
  localStorage.setItem('selectedLanguage', language);

  return language;
};

export default initUserLanguage;
