import React from 'react';
import { Avatar as MuiAvatar, Box, Chip, Skeleton, AvatarProps, SxProps, useTheme } from '@mui/material';
import { RoleSlug } from '@askporter/client-grieg-lyric';
import Icon, { IconSize, IconProps } from '../Icon';
import { Typography } from '../Typography';
import { AvatarBadge, avatarLoadingVariantMap } from './utils';

export enum AvatarSize {
  'XS' = 24,
  'SM' = 32,
  'MD' = 40,
  'LG' = 48,
  'XL' = 56,
}

const typographyVariantMap: Record<AvatarSize, SxProps> = {
  [AvatarSize.XS]: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  [AvatarSize.SM]: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.16px',
    textTransform: 'uppercase',
  },
  [AvatarSize.MD]: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.14px',
    textTransform: 'uppercase',
  },
  [AvatarSize.LG]: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.14px',
    textTransform: 'uppercase',
  },
  [AvatarSize.XL]: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.14px',
    textTransform: 'uppercase',
  },
};

export const roleIconMap: Record<string, Partial<IconProps>> = {
  [RoleSlug.PlatAdmin]: { folder: 'system/06-user' },
  [RoleSlug.ClientAdmin]: { folder: 'people/people-types/01-client-admin' },
  [RoleSlug.AgentSpr]: { folder: 'people/people-types/02-super-agent' },
  [RoleSlug.Agent]: { folder: 'people/people-types/03-agent' },
  [RoleSlug.OwnerSpr]: { folder: 'people/people-types/04-super-owner' },
  [RoleSlug.Owner]: { folder: 'people/people-types/05-owner' },
  [RoleSlug.SvcusrSpr]: { folder: 'people/people-types/06-super-service-user' },
  [RoleSlug.Svcusr]: { folder: 'people/people-types/07-service-user' },
  [RoleSlug.Unreg]: { folder: 'system/06-user' },
  [RoleSlug.SuppSpr]: { folder: 'people/people-types/08-super-supplier' },
  [RoleSlug.Supp]: { folder: 'people/people-types/09-supplier' },
};

const userIconSize = {
  [AvatarSize.XS]: IconSize.SM,
  [AvatarSize.SM]: IconSize.SM,
  [AvatarSize.MD]: IconSize.MD,
  [AvatarSize.LG]: IconSize.MD,
  [AvatarSize.XL]: IconSize.MD,
};

export interface CustomAvatar {
  /**
   * mode to set type of avatar
   */
  mode: 'icon' | 'image' | 'initials' | 'user';
  /**
   * variant to set shape of avatar (e.g. rounded corners)
   */
  variant: AvatarProps['variant'];
  /**
   * imagepath for either icon or image depending on mode
   */
  imagePath?: string;
  /**
   * iconFillColor for for when: mode === icon
   */
  iconFillColor?: string;
  /**
   * fallback for icon if mode === icon
   */
  fallback?: JSX.Element | string;
  /**
   * // where the color is not returned by the server, we can ensure the Icon background is defaulted
   */
  colorMask?: string;
  /**
   * avatar size
   */
  size?: { container: AvatarSize; icon?: IconSize };
  /**
   * avatar background color
   */
  bgcolor?: string;
  /**
   * text beside Avatar
   */
  avatarText?: AvatarText;
  /**
   * initials required for initial mode
   */
  initials?: string;
  /**
   * initials required for initial mode
   */
  avatarUser?: {
    userRole?: RoleSlug;
    isMultiple?: boolean;
  };
}

export interface AvatarText {
  mode: 'label' | 'header';
  title: string;
  subTitle?: string;
  subTitleIcon?: JSX.Element;
}

interface BaseAvatarProps {
  /**
   * object defining the avatar props
   */
  avatar: CustomAvatar;
  /**
   * alt text for the avatar
   */
  altText?: string;
  /**
   * boolean to indicate if the avatar is loading
   */
  isLoading?: boolean;
  /**
   * boolean to indicate if the avatar badge should show
   */
  badge?: { isVisible: boolean; color?: string };
  /**
   * (optional) the data-testid string for the Avatar
   */
  dataTestId?: string;
  children?: JSX.Element;
}

/**
 * Custom Avatar component that can be used to render an icon or an image, with colour masking
 */
export const Avatar: React.FC<React.PropsWithChildren<BaseAvatarProps>> = ({
  avatar,
  isLoading,
  altText = 'avatar-alt-text',
  badge = { isVisible: false },
  dataTestId,
  children,
}) => {
  const {
    palette: {
      action: { active },
    },
  } = useTheme();
  const avatarSize = avatar.size?.container || AvatarSize.XL;
  const iconSize = avatar.size?.icon || IconSize.MD;
  const userIcon: Partial<IconProps> = avatar.avatarUser?.userRole
    ? roleIconMap[avatar.avatarUser?.userRole]
    : { folder: 'system/06-user' };
  const iconProps: Partial<IconProps> = avatar.avatarUser?.isMultiple ? { folder: 'system/03-people' } : userIcon;

  const muiAvatar = (
    <MuiAvatar
      variant={avatar.variant}
      sx={{ ...(avatar.bgcolor && { bgcolor: avatar.bgcolor }) }}
      src={avatar.imagePath && (avatar.mode === 'image' || avatar.mode === 'user') ? avatar.imagePath : undefined}
      alt={altText}
      data-testid={dataTestId ? dataTestId : `${avatar.variant}-avatar`}
    >
      {avatar.mode === 'user' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `${avatarSize}px`,
            width: `${avatarSize}px`,
            bgcolor: avatar.bgcolor ? avatar.bgcolor : '#34495e',
          }}
          data-testid="user-avatar-content"
        >
          {avatar.initials ? (
            <Box sx={typographyVariantMap[avatarSize]}>{avatar.initials}</Box>
          ) : (
            <Icon {...iconProps} size={userIconSize[avatarSize]} fillColor="#fff" />
          )}
        </Box>
      ) : (avatar.mode === 'image' && !avatar.imagePath) || avatar.mode === 'initials' ? (
        <Box
          sx={{
            height: `${avatarSize}px`,
            width: `${avatarSize}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {avatar.initials ? (
            <Box sx={typographyVariantMap[avatarSize]} data-testid="avatar-initials">
              {avatar.initials}
            </Box>
          ) : (
            avatar.fallback
          )}
        </Box>
      ) : avatar.mode === 'icon' && children ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `${avatarSize}px`,
            width: `${avatarSize}px`,
            bgcolor: avatar.bgcolor ? avatar.bgcolor : '#34495e',
          }}
          data-testid="user-avatar-content"
        >
          {children}
        </Box>
      ) : avatar.mode === 'icon' ? (
        <Icon
          {...(avatar.imagePath && { fullPath: avatar.imagePath })}
          size={iconSize}
          {...(avatar.colorMask && { colorMask: { size: avatarSize, color: avatar.colorMask } })}
          data-testid="avatar-main-icon"
          fillColor={avatar.iconFillColor ? avatar.iconFillColor : '#ffffff'}
        />
      ) : avatar.fallback ? (
        avatar.fallback
      ) : (
        ''
      )}
    </MuiAvatar>
  );

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant={avatarLoadingVariantMap[avatar.variant || 'rounded']}
          animation="wave"
          width={avatarSize}
          height={avatarSize}
          sx={{ mr: 4 }}
          data-testid="loading-skeleton"
        />
      ) : null}
      {avatar && !isLoading ? (
        <Box display="flex" flexDirection="row" alignItems={'center'}>
          <Box
            sx={{
              height: `${avatarSize}px`,
              width: `${avatarSize}px`,
              '& .MuiAvatar-root': { height: '100%', width: '100%' },
              flex: `${avatarSize}px 0 0`,
            }}
          >
            {badge?.isVisible ? (
              <AvatarBadge avatarVariant={avatar.variant} badgeColor={badge?.color} isVisible={true}>
                {muiAvatar}
              </AvatarBadge>
            ) : (
              <>{muiAvatar}</>
            )}
          </Box>
          {avatar?.avatarText && (
            <Box sx={{ ml: 3 }}>
              <Typography
                {...(avatar.avatarText.mode === 'header'
                  ? { variant: 'h3', 'data-testid': 'avatar-header-title' }
                  : { variant: 'body2', 'data-testid': 'avatar-label-title' })}
              >
                {avatar.avatarText.title}
              </Typography>
              {avatar.avatarText.subTitleIcon && avatar.avatarText.subTitle ? (
                <Chip
                  data-testid="avatar-subtitle-icon"
                  icon={avatar.avatarText.subTitleIcon}
                  label={avatar.avatarText.subTitle}
                  sx={{
                    mt: 1,
                    p: 0,
                    background: 'transparent',
                    height: 'unset',
                    color: active,
                    '& .MuiChip-icon': { marginLeft: '0px', color: active },
                  }}
                />
              ) : (
                avatar.avatarText.subTitle && (
                  <Typography
                    {...(avatar.avatarText.mode === 'header'
                      ? { variant: 'body2', 'data-testid': 'avatar-header-subtitle' }
                      : { variant: 'caption', 'data-testid': 'avatar-label-subtitle' })}
                    color={active}
                  >
                    {avatar.avatarText.subTitle}
                  </Typography>
                )
              )}
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};
