import React from 'react';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@askporter/component-library';

interface InitialViewProps {
  t: (key: string) => string;
  setDialogView: (view: 'INITIAL' | 'CUSTOMISE') => void;
  handleAcceptAllOnClick: () => void;
  isSmallDevice: boolean;
}

const InitialView: React.FC<React.PropsWithChildren<InitialViewProps>> = ({
  t,
  setDialogView,
  handleAcceptAllOnClick,
  isSmallDevice,
}) => {
  return (
    <>
      <DialogTitle variant="h3">{t('cookie_banner:initial_view:title')}</DialogTitle>

      <Box display={isSmallDevice ? 'block' : 'flex'} justifyContent="space-between">
        <DialogContent>{t('cookie_banner:initial_view:description')}</DialogContent>
        <DialogActions sx={{ pr: 6, ml: 'auto' }} disableSpacing>
          <Button onClick={() => setDialogView('CUSTOMISE')}>{t('cookie_banner:initial_view:customise_button')}</Button>
          <Button variant="contained" sx={{ ml: 6 }} onClick={handleAcceptAllOnClick}>
            {t('cookie_banner:initial_view:accept_button')}
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default InitialView;
