import { DayOfWeek } from '@askporter/client-grieg-lyric';
import { DayTimeRangeType } from '../types';

export const parseDayTime = (
  dayTimeArray: Array<DayTimeRangeType>,
): Record<DayOfWeek, { fromTime: string; toTime: string }> => {
  const result: Record<string, { fromTime: string; toTime: string }> = {};

  (dayTimeArray || []).map((time) => {
    time?.days?.forEach((day) => {
      if (time?.fromTime && time?.toTime) {
        const formattedFromTime = time.fromTime?.split(':');
        const formattedToTime = time.toTime?.split(':');
        result[day] = {
          fromTime: `${formattedFromTime[0]}:${formattedFromTime[1]}`,
          toTime: `${formattedToTime[0]}:${formattedToTime[1]}`,
        };
      } else {
        result[day] = {
          fromTime: null,
          toTime: null,
        };
      }
    });
  });

  return result;
};
