import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { FieldProps } from '@rjsf/core';
import { FilePath } from '@askporter/client-grieg-lyric';
import { MediaList } from '../../';

export const MediaListField: React.FC<React.PropsWithChildren<FieldProps>> = (props: FieldProps) => {
  const { formData, schema } = props;

  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = props.formContext;

  const images = formData as Array<{ file: FilePath }>;

  if (!images?.length) return null;

  return <MediaList title={`${schema.title}`} images={images} t={t} isSmallDevice={isSmallDevice} />;
};
