import { NewFile } from '@askporter/client-grieg-lyric';
import { APIOptions, SimplifiedIAPIClient, UploadedFile } from '../';

export const uploadFiles = async (
  filesToUpload: Array<File>,
  API: () => SimplifiedIAPIClient,
  captureException: (...args: unknown[]) => void,
): Promise<Array<UploadedFile>> => {
  return Promise.all(
    filesToUpload.map(async (file) => {
      try {
        const payload: NewFile = { pathPrefix: '/', filename: file.name };

        const {
          data: { uid, urn, signedUploadPath },
        } = await API().post({ path: 'files', payload });

        const putRequest: APIOptions = {
          path: signedUploadPath,
          payload: file,
          authHeader: false,
          isGrieg: false,
        };

        if (file.type) {
          putRequest.additionalHeaders = { 'Content-Type': file.type };
        }

        await API().put(putRequest);

        const objectURL = URL.createObjectURL(file); //this a temporary blob url to use whilst the backend file path is resolved.
        return { uid, urn, name: file.name, signedUploadPath, addedAt: new Date().toISOString(), objectURL };
      } catch (error) {
        captureException(error, { extra: { fileName: file?.name, response: error?.response?.data?.message } });
        return { name: file?.name, error };
      }
    }),
  );
};
