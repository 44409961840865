import React from 'react';
import { TaskFullSla, SlaCurrentStatusReadOnly, SlaType } from '@askporter/client-grieg-lyric';
import { slaIcons } from '@askporter/utils';
import { Chip } from '../../Chip';
import Icon, { IconSize } from '../../Icon';
import {
  getDueStatusSlaDisplay,
  getResponseStatusSlaDisplay,
  getResolveStatusSlaDisplay,
  getSlaStatus,
  SlaFilter,
} from '../utils';

export interface TaskCardSlaDisplayProps {
  /**
   * translation method
   */
  t: (key: string) => string;
  /**
   * sla object
   */
  sla: TaskFullSla;
  /**
   * filter gets passed when filter task search
   */
  slaFilter?: SlaFilter;
}

const slaChipColour = {
  [SlaCurrentStatusReadOnly.Running]: 'primary.main',
  [SlaCurrentStatusReadOnly.Paused]: 'warning.main',
  [SlaCurrentStatusReadOnly.CompletedOnTime]: 'success.main',
  [SlaCurrentStatusReadOnly.CompletedLate]: 'error.main',
  [SlaCurrentStatusReadOnly.Overdue]: 'error.main',
  [SlaCurrentStatusReadOnly.Na]: undefined as any,
};

/**
 * TaskCardSla to show SLA data within TaskCard
 *
 */
export const TaskCardSlaDisplay: React.FC<React.PropsWithChildren<TaskCardSlaDisplayProps>> = ({
  sla,
  t,
  slaFilter,
}: TaskCardSlaDisplayProps): JSX.Element => {
  if (!sla || sla?.type === SlaType.None) return null;

  const statusObject = getSlaStatus({ sla, slaFilter });
  if (!statusObject) return null;
  const { slaStatusType = null, currentStatus = null } = statusObject;

  const slaDisplayText =
    slaStatusType === 'DueStatus'
      ? getDueStatusSlaDisplay({
          slaDue: sla.due,
          t,
        })
      : slaStatusType === 'RespondStatus'
      ? getResponseStatusSlaDisplay({
          slaResponse: sla.response,
          t,
        })
      : slaStatusType === 'ResolveStatus'
      ? getResolveStatusSlaDisplay({
          slaResolve: sla.resolution,
          t,
        })
      : null;

  return (
    <>
      {slaDisplayText ? (
        <Chip
          data-testid="sla-chip"
          color="default"
          label={slaDisplayText}
          icon={<Icon size={IconSize.SM} fillColor="white" folder={slaIcons[currentStatus]} />}
          sx={{
            backgroundColor: slaChipColour[currentStatus],
            color: '#FFFFFF',
          }}
        />
      ) : null}
    </>
  );
};
