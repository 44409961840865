import { SearchTaskRequest, SearchTaskSort } from '@askporter/client-grieg-lyric';
import { handleFreeTextSearch } from '@askporter/utils';
import { LinkedTasksState, LinkedTasksActions } from '../types';

export const initialState = (filter?: SearchTaskRequest['filter']): LinkedTasksState => ({
  search: {
    freeText: '',
    sort: SearchTaskSort.Recommended,
    page: 1,
    extendedMetadataStats: [],
    filter: {
      created: {
        before: null,
        after: null,
      },
      // should I add other date filters here?
      assignedTeam: [],
      assignedUser: [],
      assignedTo: [],
      statusType: [],
      priority: [],
      status: [],
      taskType: [],
      linkedAsset: [],
      linkedOrg: [],
      linkedTeam: [],
      linkedUser: [],
      ...filter,
    },
  },
  isSearchEnabled: true,
  hasUserSelectedSort: false,
});

const linkedTasksReducer = (state: LinkedTasksState, action: LinkedTasksActions): LinkedTasksState => {
  switch (action.type) {
    case 'SET_SEARCH_FREE_TEXT':
      return {
        ...state,
        search: handleFreeTextSearch({
          search: state.search,
          freeText: action.payload,
          defaultSort: initialState().search.sort,
          hasUserSelectedSort: state.hasUserSelectedSort,
        }),
      };
    case 'SET_PAGE':
      return { ...state, search: { ...state.search, page: action.payload } };
    case 'SET_SORT':
      return { ...state, search: { ...state.search, page: 1, sort: action.payload }, hasUserSelectedSort: true };
    case 'SET_FILTERS':
      return { ...state, search: { ...state.search, page: 1, filter: { ...state.search.filter, ...action.payload } } };
    case 'SET_IS_SEARCH_ENABLED':
      return { ...state, isSearchEnabled: action.payload, search: { ...state.search } };
    case 'CLEAR_ALL_FILTERS':
      return {
        ...state,
        search: {
          ...initialState().search,
          filter: { ...initialState().search.filter, ...action.payload },
          extendedMetadataStats: state?.search?.extendedMetadataStats || initialState().search.extendedMetadataStats,
        },
      };
    case 'SET_EXTENDED_METADATA_STATS':
      return { ...state, search: { ...state.search, extendedMetadataStats: action.payload } };

    default:
      return state;
  }
};

export default linkedTasksReducer;
