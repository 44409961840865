import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { DayOfWeek } from '@askporter/client-grieg-lyric';
import { DateAndTimePicker, DateAndTimePickerProps } from '../../../../../';
import { LoadingButton } from '../../../../Buttons/LoadingButton';
import { RoundButton, RoundButtonSize } from '../../../../Buttons/RoundButton';
import { Dialog } from '../../../../Dialog';
import { DayTimeRangeType } from '../../types';
import {
  defaultDays,
  days,
  getDays,
  filterNoServiceTimeDays,
  parseDayTime,
  mergeDayTimeState,
  getLocalizedTimeFormat,
} from '../../utils';

export interface SelectDayTimeProps {
  title?: string;
  dataTestId?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  isLoading?: boolean;
  isVisible: boolean;
  handleClose: () => void;
  setIsModalVisible: (isModalVisible: boolean) => void;
  isSmallDevice: boolean;
  selectedDays?: Record<string, boolean>;
  setSelectedDays: (selectedDays: Record<string, boolean>) => void;
  onAdd: (value: Array<DayTimeRangeType>) => void;
  dayTimes: Record<DayOfWeek, { fromTime: string; toTime: string }>;
  addDayTime: (dayTimes: Record<DayOfWeek, { fromTime: string; toTime: string }>) => void;
  value: Array<DayTimeRangeType>;
  fromTimeValue: Date | null;
  toTimeValue: Date | null;
  setFromTimeValue: (fromTimeValue: Date | null) => void;
  setToTimeValue: (toTimeValue: Date | null) => void;
  noServiceCheckbox: boolean;
  setNoServiceCheckbox: (noServiceCheckbox: boolean) => void;
}

/**
 * Allows the user to set a time for a week day
 */
export const SelectDayTimeModal: React.FC<React.PropsWithChildren<SelectDayTimeProps>> = ({
  t,
  title,
  dataTestId,
  isLoading,
  isVisible,
  setIsModalVisible,
  handleClose,
  isSmallDevice,
  selectedDays = defaultDays,
  setSelectedDays,
  onAdd,
  value,
  dayTimes,
  addDayTime,
  fromTimeValue,
  toTimeValue,
  setFromTimeValue,
  setToTimeValue,
  noServiceCheckbox,
  setNoServiceCheckbox,
}: SelectDayTimeProps) => {
  const localeProps: DateAndTimePickerProps['localeProps'] = {
    okText: t('common:date_picker:ok'),
    clearText: t('common:date_picker:clear'),
    cancelText: t('common:date_picker:cancel'),
    toggleVisibilityText: t('common:date_picker:toggle_visibility'),
  };

  const _onChange = (time: string | null, type: 'fromTime' | 'toTime' | 'all') => {
    if (type === 'fromTime') {
      setFromTimeValue(time ? new Date(time) : null);
    } else if (type === 'toTime') {
      setToTimeValue(time ? new Date(time) : null);
    } else {
      setFromTimeValue(null);
      setToTimeValue(null);
    }
  };

  const _onAdd = () => {
    const v = [
      {
        days: getDays(selectedDays),
        fromTime: fromTimeValue ? `${fromTimeValue.toTimeString().substring(0, 5)}` : null,
        toTime: toTimeValue ? `${toTimeValue.toTimeString().substring(0, 5)}` : null,
      },
    ];
    const payload = filterNoServiceTimeDays({ ...dayTimes, ...parseDayTime(v) });

    const newState = [
      {
        days: getDays(selectedDays),
        fromTime: fromTimeValue ? `${fromTimeValue?.toTimeString().substring(0, 5)}` : null,
        toTime: toTimeValue ? `${toTimeValue?.toTimeString().substring(0, 5)}` : null,
      },
    ];

    const mergedState = mergeDayTimeState(value || [], newState);

    onAdd(mergedState);
    addDayTime(payload);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoServiceCheckbox(e.target.checked);
    _onChange(null, 'all');
  };

  return (
    <Dialog
      title={title}
      isOpen={isVisible}
      isSmallDevice={isSmallDevice}
      onClose={handleClose}
      containerWidth="544px"
      actions={
        <>
          <Button onClick={() => setIsModalVisible(false)}>{t('common:cancel')}</Button>
          <LoadingButton
            onClick={() => _onAdd()}
            isLoading={isLoading}
            disabled={(!fromTimeValue || !toTimeValue) && !noServiceCheckbox}
            type="submit"
          >
            {t('common:add')}
          </LoadingButton>
        </>
      }
    >
      <Box data-testid={dataTestId} sx={{ display: 'flex', gap: isSmallDevice ? 3 : 6.5 }}>
        {Object.keys(days).map((day, i) => (
          <RoundButton
            onClick={() => setSelectedDays({ ...selectedDays, [i]: !selectedDays[i] })}
            isSelected={selectedDays[i] === true}
            size={isSmallDevice ? RoundButtonSize.SM : RoundButtonSize.LG}
            key={`${day}-${i}`}
            aria-label={`${t(`common:day_of_week:${day}`)} - ${t(
              `day_time:edit:${selectedDays[i] === true ? 'selected' : 'unselected'}`,
            )}`}
          >
            {t(`common:day_of_week:${day}`)?.substring(0, 1)}
          </RoundButton>
        ))}
      </Box>
      <Box sx={{ mt: 6 }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={noServiceCheckbox} onChange={handleCheckboxChange} />}
            label={t('day_time:select_day_time:no_service')}
          />
          {!noServiceCheckbox && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
              <DateAndTimePicker
                value={fromTimeValue}
                onChange={(v) => _onChange(v, 'fromTime')}
                isSmallDevice={isSmallDevice}
                localeProps={localeProps}
                controls={['time']}
                timeFormat={getLocalizedTimeFormat()}
                inputProps={{
                  label: t('day_time:select_day_time:from_time'),
                  inputProps: {
                    'data-testid': 'from-time-picker',
                    key: new Date(fromTimeValue).getTime(),
                  },
                }}
                max={toTimeValue}
              />
              <DateAndTimePicker
                value={toTimeValue}
                onChange={(v) => _onChange(v, 'toTime')}
                isSmallDevice={isSmallDevice}
                localeProps={localeProps}
                controls={['time']}
                timeFormat={getLocalizedTimeFormat()}
                inputProps={{
                  label: t('day_time:select_day_time:to_time'),
                  inputProps: {
                    'data-testid': 'to-time-picker',
                    key: new Date(toTimeValue).getTime(),
                  },
                }}
                min={fromTimeValue}
              />
            </Box>
          )}
        </FormGroup>
      </Box>
    </Dialog>
  );
};
