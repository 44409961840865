import { SlaCurrentStatusReadOnly } from '@askporter/client-grieg-lyric';
import { IconFolder } from '../types';

export const slaIcons: Record<string, IconFolder> = {
  [SlaCurrentStatusReadOnly.Running]: 'tasks/task-list/task-card-sla/sla-running',
  [SlaCurrentStatusReadOnly.Paused]: 'tasks/task-list/task-card-sla/sla-paused',
  [SlaCurrentStatusReadOnly.CompletedOnTime]: 'tasks/task-list/task-card-sla/sla-completed',
  [SlaCurrentStatusReadOnly.CompletedLate]: 'tasks/task-list/task-card-sla/sla-completed-late',
  [SlaCurrentStatusReadOnly.Overdue]: 'tasks/task-list/task-card-sla/sla-overdue',
};
