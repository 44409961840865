import React from 'react';
import { Button, Box } from '@mui/material';
import { FilePath } from '@askporter/client-grieg-lyric';
import { Typography, FilePreview, ThumbnailPreview } from '../../';

interface MediaListProps {
  title?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  images: Array<{ file: FilePath }>;
}

/**
 * Renders a list of media thumbnails which show a full screen preview when clicked
 *
 * @param title - header / title for media list
 * @param images - media array of file paths
 * @param t - the translation function
 * @param isSmallDevice - small viewport or not
 */
export const MediaList: React.FC<React.PropsWithChildren<MediaListProps>> = ({
  title,
  t,
  isSmallDevice,
  images,
}: MediaListProps) => {
  const [imagePreview, setImagePreview] = React.useState<FilePath | undefined>();

  if (!images?.length) return null;

  return (
    <>
      {title && (
        <Typography variant="caption" component="h3" mt={4} mb={2}>
          {title}
        </Typography>
      )}
      <Box sx={{ width: '100%', height: 'auto', display: 'flex', gap: 4, flexWrap: 'wrap' }} data-testid="media-list">
        {images.map((item, idx) => {
          if (item?.file?.filePath) {
            return (
              <Button
                variant="unstyled"
                key={item.file.fileUrn || `btn-${idx}`}
                onClick={() => setImagePreview(item.file)}
              >
                <ThumbnailPreview
                  filePath={item.file.filePath}
                  imgAlt={t('ns.task_details:media:list_item:alt_text', { number: idx + 1 })}
                />
              </Button>
            );
          }
        })}
      </Box>
      {imagePreview && (
        <FilePreview
          t={t}
          isSmallDevice={isSmallDevice}
          handleClose={() => setImagePreview(undefined)}
          file={imagePreview}
          title={t('ns.task_details:media:modal:alt_text')}
        />
      )}
    </>
  );
};
