import React from 'react';
import { Box } from '@mui/material';
import { ReturnButton } from '../ReturnButton';
import { Typography } from '../Typography';

export interface PageHeaderProps {
  /**
   * If true the return button will be visible
   */
  showReturnButton?: boolean;
  /**
   * the page title for example "The best page"
   */
  title: string;
  /**
   * description text, displayed below the title
   */
  description?: string;
  /**
   * Translation function
   */
  t: (key: string) => string;
}

export const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  t,
  showReturnButton,
  title,
  description,
}: PageHeaderProps) => {
  return (
    <Box p={6} pt={showReturnButton ? 8 : undefined}>
      {showReturnButton ? <ReturnButton t={t} sx={{ textTransform: 'none' }} /> : null}
      <Typography variant="h3" mt={showReturnButton ? 6 : undefined}>
        {title}
      </Typography>
      {description ? (
        <Typography variant="caption" color="text.secondary">
          {description}
        </Typography>
      ) : null}
    </Box>
  );
};
