import { PermissionRole, RoleSlug } from '@askporter/client-grieg-lyric';

// refer to issue https://github.com/askporter/front-end/issues/1533
export const getAllowedRolesByRolePolicy = (policy: PermissionRole): Array<RoleSlug> => {
  const mapping = {
    [PermissionRole.Svcusr]: [RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
    [PermissionRole.SvcusrSpr]: [RoleSlug.SvcusrSpr],
    [PermissionRole.Supp]: [RoleSlug.Supp, RoleSlug.SuppSpr],
    [PermissionRole.SuppSpr]: [RoleSlug.SuppSpr],
    [PermissionRole.Manager]: [
      RoleSlug.Agent,
      RoleSlug.AgentSpr,
      RoleSlug.Owner,
      RoleSlug.OwnerSpr,
      RoleSlug.ClientAdmin,
      RoleSlug.PlatAdmin,
    ],
    [PermissionRole.Owner]: [RoleSlug.Owner, RoleSlug.OwnerSpr],
    [PermissionRole.OwnerSpr]: [RoleSlug.OwnerSpr],
    [PermissionRole.Agent]: [RoleSlug.Agent, RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    [PermissionRole.AgentSpr]: [
      RoleSlug.AgentSpr,
      RoleSlug.Owner,
      RoleSlug.OwnerSpr,
      RoleSlug.ClientAdmin,
      RoleSlug.PlatAdmin,
    ],
    [PermissionRole.ClientAdmin]: [RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    [PermissionRole.PlatAdmin]: [RoleSlug.PlatAdmin],
  };

  return mapping[policy];
};
