import { RoleSlug } from '@askporter/client-grieg-lyric';
import { checkPermissions } from '../../';

export const roleOptions = (currentRoleSlug: RoleSlug): Array<RoleSlug> => {
  if (checkPermissions(currentRoleSlug, [RoleSlug.PlatAdmin, RoleSlug.ClientAdmin, RoleSlug.AgentSpr])) {
    return [RoleSlug.Svcusr, RoleSlug.Supp, RoleSlug.Owner, RoleSlug.Agent];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.Agent])) {
    return [RoleSlug.Svcusr, RoleSlug.Supp, RoleSlug.Owner];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OwnerSpr])) {
    return [RoleSlug.Svcusr, RoleSlug.Owner];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.Owner])) {
    return [RoleSlug.Svcusr];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SuppSpr])) {
    return [RoleSlug.Supp];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SvcusrSpr])) {
    return [RoleSlug.Svcusr];
  }
};

export const superRoleOptions = (currentRoleSlug: RoleSlug): Array<RoleSlug> => {
  if (checkPermissions(currentRoleSlug, [RoleSlug.PlatAdmin])) {
    return [
      RoleSlug.SvcusrSpr,
      RoleSlug.SuppSpr,
      RoleSlug.OwnerSpr,
      RoleSlug.AgentSpr,
      RoleSlug.ClientAdmin,
      RoleSlug.PlatAdmin,
    ];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.ClientAdmin])) {
    return [RoleSlug.SvcusrSpr, RoleSlug.SuppSpr, RoleSlug.OwnerSpr, RoleSlug.AgentSpr, RoleSlug.ClientAdmin];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.Agent])) {
    return [RoleSlug.SvcusrSpr, RoleSlug.SuppSpr, RoleSlug.OwnerSpr];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.AgentSpr])) {
    return [RoleSlug.SvcusrSpr, RoleSlug.SuppSpr, RoleSlug.OwnerSpr, RoleSlug.AgentSpr];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OwnerSpr])) {
    return [RoleSlug.SvcusrSpr, RoleSlug.OwnerSpr];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.Owner, RoleSlug.SvcusrSpr])) {
    return [RoleSlug.SvcusrSpr];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SuppSpr, RoleSlug.SvcusrSpr])) {
    return [RoleSlug.SuppSpr];
  }
};
