import {
  TaskTypeGroupListExternal,
  TaskTypeGroupListExternalTaskTypeGroups,
  TaskTypeSummaryExternal,
} from '@askporter/client-grieg-lyric';

interface TaskTypeSummaryWithGroup extends TaskTypeSummaryExternal {
  groupDisplayName: string;
  groupUid: string;
}

/**
 * Given Task Types information from Grieg, this formats them
 * so they can be used displayed by group and used as options
 * in the Autocomplete component
 *
 * @param data - the TaskTypeGroupListExternal data
 * @param reportException - function to report an error e.g. captureException from askporter/exception-logger
 */
export const groupTaskTypes = async (
  data: TaskTypeGroupListExternal,
  reportException?: (error: Error) => unknown,
): Promise<Array<TaskTypeSummaryWithGroup>> => {
  if (Array.isArray(data?.taskTypeGroups) && data?.taskTypeGroups?.length >= 1) {
    return (
      data?.taskTypeGroups
        // sort groups by display name
        ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
        .reduce((acc: Array<TaskTypeSummaryWithGroup>, next: TaskTypeGroupListExternalTaskTypeGroups) => {
          const typesWithGroupInfo: Array<TaskTypeSummaryWithGroup> = next.taskTypes
            // add group data to the individual tasks and sort by display name
            .map((type) => ({ ...type, groupDisplayName: next.displayName, groupUid: next.uid }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));

          return [...acc, ...typesWithGroupInfo];
        }, [])
    );
  } else {
    if (data?.taskTypeGroups !== undefined && !Array.isArray(data?.taskTypeGroups) && reportException)
      reportException(
        Error(
          `Task type groups is not an array and is not undefined. Payload: ${JSON.stringify(data?.taskTypeGroups)}`,
        ),
      );

    return [];
  }
};
