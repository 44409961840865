import { datadogRum } from '@datadog/browser-rum';
import { ProfileReadExternal } from '@askporter/client-grieg-lyric';
import { Config } from '../types';

const permittedRumEnvs = ['development', 'staging', 'production'];

/**
 * Function used to determine if RUM is allowed, returns true if it is allowed and false if not
 */
export const areWeAllowedRum = (flags: Config & { cookieConsent: boolean }): boolean => {
  try {
    if (flags.IS_CI) return false;
    if (flags.IS_SERVED_FROM_MOCK_SERVER) return false;
    if (!flags.cookieConsent) return false;
    if (!permittedRumEnvs.includes(flags.BUILD_TARGET_ENV) && flags.ENABLE_LOCAL_RUM !== true) return false;
    return true;
  } catch (_err) {
    return false;
  }
};

/**
 * Instrument Datadog RUM
 */
export const instrumentRum = (config: Config): void => {
  let env = config?.BUILD_TARGET_ENV;

  // align with other datadog services
  if (env === 'development') env = 'dev';
  if (env === 'staging') env = 'staging';
  if (env === 'production') env = 'prod';

  datadogRum.init({
    env,
    version: config?.NEXT_RELEASE_VERSION,
    applicationId: '2895f8a4-0c42-4786-bab2-07973d846041',
    clientToken: 'pub2c873054a21755305bd53705e7253c5c',
    site: 'datadoghq.eu',
    service: 'frontend',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    allowedTracingUrls: [`${window.location.origin}/api`],
  });

  datadogRum.startSessionReplayRecording();
};

/**
 * Assign key attributes to the datadog user to provide additional context for debugging purposes
 */
export const setRumUser = ({ uid, roleSlug }: Partial<ProfileReadExternal>): void => {
  datadogRum.setUser({
    id: uid,
    roleSlug: roleSlug,
  });
};
