import { isToday } from 'date-fns';
import { TaskFullSlaDue, TaskFullSlaResponse, SlaCurrentStatusReadOnly } from '@askporter/client-grieg-lyric';
import { formatDate } from '@askporter/utils';

export const getDueStatusSlaDisplay = ({
  slaDue,
  t,
}: {
  slaDue: TaskFullSlaDue;
  t: (key: string, options?: Record<string, string>) => string;
}): string => {
  const { dueDate, status, stoppedAt } = slaDue;

  switch (status) {
    case SlaCurrentStatusReadOnly.Running:
      if (!dueDate) {
        return t('task_list:task_card:sla:due_status:running_no_date');
      } else if (isToday(new Date(dueDate))) {
        return t('task_list:task_card:sla:due_status:running_due_today');
      } else {
        return t('task_list:task_card:sla:due_status:running_due_not_today', {
          date: formatDate(dueDate, 'dd MMM yyyy'),
        });
      }
    case SlaCurrentStatusReadOnly.CompletedOnTime:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:due_status:completed_on_time_no_date');
      }

      return t('task_list:task_card:sla:due_status:completed_on_time', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.CompletedLate:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:due_status:completed_late_no_date');
      }

      return t('task_list:task_card:sla:due_status:completed_late', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.Overdue:
      return t('task_list:task_card:sla:due_status:overdue');
  }
};

export const getResponseStatusSlaDisplay = ({
  slaResponse,
  t,
}: {
  slaResponse: TaskFullSlaResponse;
  t: (key: string, options?: Record<string, string>) => string;
}): string => {
  const { status, dueDateTime, stoppedAt } = slaResponse;

  switch (status) {
    case SlaCurrentStatusReadOnly.Running:
      if (!dueDateTime) {
        return t('task_list:task_card:sla:response:running_no_date');
      } else if (isToday(new Date(dueDateTime))) {
        return t('task_list:task_card:sla:response:running_due_today', {
          time: formatDate(dueDateTime, 'HH:mm'),
        });
      } else {
        return t('task_list:task_card:sla:response:running_due_not_today', {
          date: formatDate(dueDateTime, 'dd MMM yyyy'),
        });
      }
    case SlaCurrentStatusReadOnly.Paused:
      return t('task_list:task_card:sla:response:paused');
    case SlaCurrentStatusReadOnly.CompletedOnTime:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:response:completed_on_time_no_date');
      }

      return t('task_list:task_card:sla:response:completed_on_time', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.CompletedLate:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:response:completed_late_no_date');
      }

      return t('task_list:task_card:sla:response:completed_late', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.Overdue:
      if (!dueDateTime) {
        return t('task_list:task_card:sla:response:overdue_no_date');
      } else if (isToday(new Date(dueDateTime))) {
        return t('task_list:task_card:sla:response:overdue_today', {
          time: formatDate(dueDateTime, 'HH:mm'),
        });
      } else {
        return t('task_list:task_card:sla:response:overdue_not_today', {
          date: formatDate(dueDateTime, 'dd MMM yyyy'),
        });
      }
  }
};

export const getResolveStatusSlaDisplay = ({
  slaResolve,
  t,
}: {
  slaResolve: TaskFullSlaResponse;
  t: (key: string, options?: Record<string, string>) => string;
}): string => {
  const { status, dueDateTime, stoppedAt } = slaResolve;

  switch (status) {
    case SlaCurrentStatusReadOnly.Running:
      if (!dueDateTime) {
        return t('task_list:task_card:sla:resolve:running_no_date');
      } else if (isToday(new Date(dueDateTime))) {
        return t('task_list:task_card:sla:resolve:running_due_today', {
          time: formatDate(dueDateTime, 'HH:mm'),
        });
      } else {
        return t('task_list:task_card:sla:resolve:running_due_not_today', {
          date: formatDate(dueDateTime, 'dd MMM yyyy'),
        });
      }
    case SlaCurrentStatusReadOnly.Paused:
      return t('task_list:task_card:sla:resolve:paused');
    case SlaCurrentStatusReadOnly.CompletedOnTime:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:resolve:completed_on_time_no_date');
      }

      return t('task_list:task_card:sla:resolve:completed_on_time', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.CompletedLate:
      if (!stoppedAt) {
        return t('task_list:task_card:sla:resolve:completed_late_no_date');
      }

      return t('task_list:task_card:sla:resolve:completed_late', {
        date: formatDate(stoppedAt, 'dd MMM yyyy'),
      });
    case SlaCurrentStatusReadOnly.Overdue:
      if (!dueDateTime) {
        return t('task_list:task_card:sla:resolve:overdue_no_date');
      } else if (isToday(new Date(dueDateTime))) {
        return t('task_list:task_card:sla:resolve:overdue_today', {
          time: formatDate(dueDateTime, 'HH:mm'),
        });
      } else {
        return t('task_list:task_card:sla:resolve:overdue_not_today', {
          date: formatDate(dueDateTime, 'dd MMM yyyy'),
        });
      }
  }
};
