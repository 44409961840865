import { AssetSearchResultExternal } from '@askporter/client-grieg-lyric';

export const locationDisplayName = (location?: AssetSearchResultExternal['location']): string => {
  try {
    if (!location) return '';
    if (location?.streetAddress && location?.postalCode) return `${location?.streetAddress}, ${location?.postalCode}`;
    if (location?.streetAddress) return location?.streetAddress;
  } catch (error) {
    // no reportable error, fields are optional so just continue and return empty
  }
  return '';
};
