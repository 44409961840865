import { ThemeOptions } from '@mui/material';

type Typography = Pick<ThemeOptions, 'typography'>;

const fontFamily = "'Inter', sans-serif";
/*
  Font variants we should stick to, in order of size, largest -> smallest:
  h1, h2, h3, body1, body2, caption
*/
export const customTypography: Typography = {
  typography: {
    fontFamily,
    h1: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '30px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '28px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    caption: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '24px',
    },
  },
};
