import { GlobalStylesProps, Theme } from '@mui/system';
import { convertHexToRGBA } from '@askporter/utils';

/* 
  Mobiscroll.. has bad support for theming. This is my best
  attempt to change the colour and font during runtime. 
  otherwise they just tell you to change scss files
*/
export const globalStyleOverrides = (theme: Theme): GlobalStylesProps['styles'] => ({
  'html, body, #root': {
    minHeight: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  html: {
    overflowY: 'auto',
  },
  '& .mbsc-material *': {
    fontFamily: "'Inter', sans-serif !important",
  },
  '&.mbsc-material.mbsc-selected .mbsc-calendar-cell-text': {
    background: theme.palette.primary.main,
  },
  '& .mbsc-material.mbsc-timegrid-item.mbsc-selected': {
    background: theme.palette.primary.main,
  },
  '& .mbsc-material.mbsc-selected .mbsc-calendar-cell-text': {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: 'white',
  },
  '& .mbsc-material.mbsc-calendar-today': {
    color: theme.palette.primary.main,
  },
  '& .mbsc-material.mbsc-popup-button.mbsc-font': {
    color: theme.palette.primary.main,
  },
  '& .mbsc-material.mbsc-scroller-wheel-cont:after': {
    borderColor: theme.palette.primary.main,
  },
  '& .mbsc-material.mbsc-scroller-wheel-cont:before': {
    borderColor: theme.palette.primary.main,
  },
  '& .mbsc-fr-w': {
    backgroundColor: 'white !important',
    boxShadow: '0px 0px 20px 0px black',
  },
  '& .mbsc-sc-itm': {
    fontSize: 'inherit !important',
  },
  '& .mbsc-sc-lbl': {
    color: theme.palette.primary.main + '!important',
  },
  '& .mbsc-sc-whl-c': {
    borderColor: theme.palette.primary.main + '!important',
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 6),
  },
});
