import React from 'react';
import { useTheme } from '@mui/material';
import { captureException } from '@askporter/exception-logger';
import { copyToClipboard } from '@askporter/utils';
import {
  Breadcrumbs,
  Breadcrumb,
  CopyBreadcrumb,
  Icon,
  IconSize,
  calculateBreadcrumbProps,
} from '../../../../../../../index';

export type ResourceName = 'asset' | 'task';
interface DetailsPageHeaderBreadCrumbsProps {
  t: (key: string) => string;
  resourceName: ResourceName;
  resourceUid: string;
  copyBreadcrumbText: string;
  resourceBreadcrumbs: React.JSX.Element[];
}

export const DetailsPageHeaderBreadCrumbs: React.FC<DetailsPageHeaderBreadCrumbsProps> = ({
  t,
  resourceName,
  resourceUid,
  copyBreadcrumbText,
  resourceBreadcrumbs,
}) => {
  const {
    palette: {
      text: { secondary: breadcrumbColor },
    },
  } = useTheme();

  const resourcePaths = {
    asset: '/app/assets',
    task: '/app/tasks',
  };

  const backBreadcrumb = (
    <Breadcrumb
      key={`${resourceName}-breadcrumb`}
      text={t(`ns.${resourceName}:header:${resourceName}_list_breadcrumb`)}
      href={resourcePaths[resourceName]}
      backIcon={<Icon size={IconSize.SM} folder="actions/09-chevron-left" fillColor={breadcrumbColor} />}
    />
  );

  const copyFunction = () => {
    const isSuccess = copyToClipboard(
      `${window.location.origin}${resourcePaths[resourceName]}/${resourceUid}`,
      captureException,
    );
    const message = isSuccess
      ? t(`ns.${resourceName}_list:${resourceName}_link:clipboard_success`)
      : t(`ns.${resourceName}_list:${resourceName}_link:clipboard_error`);

    return message;
  };

  const breadcrumbs = [
    backBreadcrumb,
    ...resourceBreadcrumbs,
    ...(copyBreadcrumbText
      ? [
          <CopyBreadcrumb
            key={`copy-${copyBreadcrumbText}`}
            text={copyBreadcrumbText}
            copyFunction={copyFunction}
            aria-label={t(`ns.${resourceName}:header:${resourceName}_list_breadcrumb:aria_label`)}
          />,
        ]
      : []),
  ];

  const breadcrumbProps = calculateBreadcrumbProps({
    // the props should consider the return button when calculating, so we move it to the end of the array
    breadcrumbs: breadcrumbs?.length === 1 ? breadcrumbs : [...breadcrumbs.slice(1), backBreadcrumb],
    acceptableCharLength: 40,
  });

  return (
    <Breadcrumbs
      {...breadcrumbProps}
      sx={{ alignSelf: 'center', mb: 4, wordBreak: 'break-word' }}
      variant="body2"
      color={breadcrumbColor}
      // override some of the calculated props to ensure the resource list page link always shows
      itemsAfterCollapse={breadcrumbProps?.itemsAfterCollapse <= 1 ? 0 : breadcrumbProps?.itemsAfterCollapse - 1}
      itemsBeforeCollapse={1}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};
