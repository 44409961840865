import React, { useContext, useEffect, useReducer } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
  ClientMetadata,
} from 'amazon-cognito-identity-js';
import { RoleSlug, TenantCognito } from '@askporter/client-grieg-lyric';
import { captureException } from '@askporter/exception-logger';
import { UserAttributes } from '../types';
import {
  createUserProfile,
  cognitoSignOut,
  cognitoSignIn,
  cognitoSignUp,
  getCognitoIdToken,
  refreshCognitoSession,
  cognitoVerifyAttribute,
  cognitoConfirmWithCode,
  cognitoStartForgotPassword,
  cognitoChangePassword,
  cognitoConfirmPassword,
  cognitoGetAttributes,
  cognitoSetAttributes,
  cognitoResendConfirmationCode,
  authInitialState,
  authReducer,
} from './utils';
import {
  AuthResult,
  SessionState,
  UnauthenticatedResult,
  ApUserAttribute,
  VerificationTask,
  GetAttributesRes,
  SetAttributesRes,
  CognitoError,
} from './utils/types';

export interface AuthContextProps {
  signIn: (
    username: string,
    userpass: string,
    newPassword?: string,
    attributes?: CognitoUserAttribute[],
  ) => Promise<AuthResult>;
  signOut: () => Promise<AuthResult>;
  signUp: (
    givenname: string,
    familyname: string,
    username: string,
    userpass: string,
    userphone: string,
    clientMetadata: ClientMetadata,
  ) => Promise<AuthResult>;
  confirmWithCode: (username: string, code: string, clientMetadata?: ClientMetadata) => Promise<AuthResult>;
  startForgotPassword: (username: string) => Promise<UnauthenticatedResult>;
  confirmPassword: (username: string, code: string, password: string) => Promise<AuthResult>;
  sessionState: SessionState;
  feedbackMessage: string;
  getAPIToken: () => Promise<string | undefined>;
  getUserRole: () => RoleSlug | undefined;
  userProfile: Partial<UserAttributes> | undefined;
  authToken: string | undefined;
  verifyAttribute: (attribute: ApUserAttribute, task: VerificationTask, code?: string) => Promise<AuthResult>;
  getAttributes: () => Promise<GetAttributesRes>;
  setAttributes: (attributes: CognitoUserAttribute[]) => Promise<SetAttributesRes>;
  resendConfirmation: (username: string) => Promise<UnauthenticatedResult>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<AuthResult>;
}

export interface AuthProviderProps {
  children: JSX.Element[] | JSX.Element;
  tenantCognito: TenantCognito;
}

export const AuthContext = React.createContext<AuthContextProps>({
  signIn: undefined,
  signOut: undefined,
  signUp: undefined,
  confirmWithCode: undefined,
  confirmPassword: undefined,
  startForgotPassword: undefined,
  sessionState: 0,
  feedbackMessage: '',
  getAPIToken: undefined,
  getUserRole: undefined,
  userProfile: {
    username: '',
  },
  authToken: undefined,
  verifyAttribute: undefined,
  getAttributes: undefined,
  setAttributes: undefined,
  resendConfirmation: undefined,
  changePassword: undefined,
});

/**
 * Context provider
 */
export const useAuthProvider = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthProvider hook must be used within an AuthProvider component');
  }
  return context;
};

export let getAPIAuthToken: () => Promise<string>;
export let getCurrentUserRole: () => string;

/**
 * HoC to wrap the app, provides Auth related methods and data to children
 * @param props - react children props
 */
export const AuthProvider = ({ children, tenantCognito }: AuthProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const { feedbackMessage, sessionState, userPool, cognitoUser, cognitoUserName, userProfile, authToken } = state;

  useEffect(() => {
    // initialize cognito stuff on mount, stash objects in state to trigger automatic session refresh.
    const poolData = {
      UserPoolId: tenantCognito.userPool,
      ClientId: tenantCognito.appClientId,
    };
    const userPool = new CognitoUserPool(poolData);
    dispatch({ type: 'setPool', payload: userPool });

    const cognitoUserName = (() => {
      try {
        const username = userPool.getCurrentUser().getUsername();
        return username;
      } catch (err) {
        return null;
      }
    })();
    if (cognitoUserName) {
      dispatch({ type: 'setCognitoUserName', payload: cognitoUserName });
    } else {
      dispatch({ type: 'setAuthState', payload: { sessionState: SessionState.invalid } });
    }
  }, []);

  useEffect(() => {
    // watching for changes to userPool and cognitoUserName, these may be populated on mount, if so we try to refresh a session.
    if (userPool && cognitoUserName && sessionState !== SessionState.illegal) {
      (async () => {
        // TODO do we want to implement a retry
        try {
          await refreshSession(SessionState.pendingRehydrate);
        } catch (err) {
          // Sign the user out, if that is not possible ensure the session state represents they are not auth'd
          if (cognitoUser) {
            try {
              await signOut();
            } catch (e) {
              // if signOut returns a rejected promise then logs the exception
              const cognitoError: CognitoError = e?.error;
              captureException(cognitoError ? cognitoError : e);
            }
          } else {
            dispatch({ type: 'resetAuthState', payload: { sessionState: SessionState.invalid } });
          }
        }
      })();
    }
  }, [cognitoUserName, userPool]);

  useEffect(() => {
    // watch for changes in cognitoUser, reassess session validity on change.
    if (cognitoUser) {
      cognitoUser.getUserAttributes((err: CognitoError, res: CognitoUserAttribute[]) => {
        if (err) {
          dispatch({
            type: 'setAuthState',
            payload: { sessionState: SessionState.illegal, feedbackMessage: `${err.message}` },
          });
        } else {
          dispatch({
            type: 'setProfile',
            payload: createUserProfile(res),
          });
        }
      });
      cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          dispatch({
            type: 'setAuthState',
            payload: { sessionState: SessionState.invalid, feedbackMessage: `${err.message}` },
          });
        }
        if (session && session.isValid()) {
          dispatch({ type: 'setAuthState', payload: { sessionState: SessionState.valid } });
        }
      });
    }
  }, [cognitoUser]);

  /**
   * Refresh a session with a refresh token, user pool and user name.
   * @param refreshType - either a rehydrate or refresh value. Used in the private route component to gate content
   * @internal
   */
  const refreshSession = (refreshType: SessionState): Promise<AuthResult> => {
    const user: CognitoUser = (() => {
      try {
        const user = userPool.getCurrentUser();
        return user;
      } catch (err) {
        return new CognitoUser({ Username: cognitoUserName, Pool: userPool });
      }
    })();

    return refreshCognitoSession(dispatch, user, refreshType);
  };

  /**
   * Attempt to sign in a user or force them to change temporary password silently (if invite)
   * @param username - Credential username
   * @param password - Credential password or temporary password
   * @param newPassword - The new password to replace the temporary password
   * @param attributes - The user's attributes to update
   *
   * @returns Promise<AuthResult>
   */
  const signIn = (
    username: string,
    password: string,
    newPassword?: string,
    attributes?: CognitoUserAttribute[],
  ): Promise<AuthResult> => {
    return cognitoSignIn(username, password, dispatch, userPool, newPassword, attributes);
  };

  /**
   * Attempt to sign out
   */
  const signOut = (): Promise<AuthResult> => {
    return cognitoSignOut(dispatch, cognitoUser);
  };

  /**
   * Attempt to sign up a new user
   * @param givenname   - User's given name
   * @param familyname  - User's family name
   * @param username    - Credential username
   * @param userpass    - Credential password
   * @param userphone   - User's phone number
   * @param clientMetadata   - any additional data to send to Cognito
   */
  const signUp = (
    givenname: string,
    familyname: string,
    username: string,
    userpass: string,
    userphone: string,
    clientMetadata: ClientMetadata,
  ): Promise<AuthResult> => {
    return cognitoSignUp(givenname, familyname, username, userpass, userphone, dispatch, userPool, clientMetadata);
  };

  /**
   * Retrieve the user's API request token
   * The intended implementation will use a Cognito IdToken for platform api access
   */
  const getAPIToken = () => {
    return getCognitoIdToken(dispatch, cognitoUser);
  };

  getAPIAuthToken = getAPIToken;
  /**
   * Confirm a users account with a confirmation code
   * @param code - the auth code supplied to the user
   * @param username - user's username (email address)
   * @param clientMetadata - any additional data to send to Cognito on confirm
   */
  const confirmWithCode = (username: string, code: string, clientMetadata?: ClientMetadata): Promise<AuthResult> => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return cognitoConfirmWithCode(dispatch, code, cognitoUser, clientMetadata);
  };

  /**
   * Initiates the forgotten password process by sending an email to the provided username if it exists
   * @param username - user's username (email address)
   */
  const startForgotPassword = (username: string): Promise<UnauthenticatedResult> => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return cognitoStartForgotPassword(cognitoUser, dispatch);
  };

  /**
   * Confirm a user's new password with confirmation code
   * @param username - user's username (email address)
   * @param code - the auth code supplied to the user
   * @param password - the password supplied by the user
   */
  const confirmPassword = (username: string, code: string, password: string): Promise<AuthResult> => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return cognitoConfirmPassword(cognitoUser, code, password, dispatch);
  };

  /**
   * Changes the user's password using the old and new password
   * @param oldPassword - the old password
   * @param newPassword - the new password
   */
  const changePassword = (oldPassword: string, newPassword: string): Promise<AuthResult> => {
    return cognitoChangePassword(oldPassword, newPassword, cognitoUser, dispatch);
  };

  /**
   * Get a verification code for a given attribute
   * @param attribute - the attribute to verify
   */
  const verifyAttribute = (attribute: ApUserAttribute, task: VerificationTask, code?: string): Promise<AuthResult> => {
    return cognitoVerifyAttribute(dispatch, cognitoUser, attribute, task, code);
  };

  /**
   * Get the current user's attributes
   */
  const getAttributes = (): Promise<GetAttributesRes> => {
    return cognitoGetAttributes(dispatch, cognitoUser);
  };

  /**
   * Set the current user's attributes
   * @param attributes - the attributes to set
   */
  const setAttributes = (attributes: CognitoUserAttribute[]): Promise<SetAttributesRes> => {
    return cognitoSetAttributes(dispatch, cognitoUser, attributes);
  };

  /**
   * Attempts to resend the account confirmation email
   * @param username - user's username (email address)
   */
  const resendConfirmation = (username: string): Promise<UnauthenticatedResult> => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return cognitoResendConfirmationCode(cognitoUser, dispatch);
  };

  /**
   * For obtaining the current user role
   */
  const getUserRole = () => userProfile?.['custom:ap_role'];

  getCurrentUserRole = getUserRole;

  const value = {
    confirmWithCode,
    startForgotPassword,
    confirmPassword,
    feedbackMessage,
    getAPIToken,
    sessionState,
    signIn,
    signOut,
    signUp,
    userProfile,
    getUserRole,
    authToken,
    verifyAttribute,
    getAttributes,
    setAttributes,
    resendConfirmation,
    changePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
