import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ExportButton, ExportButtonProps } from '../../../../Buttons/ExportButton';
import { Typography } from '../../../../Typography';
import CreateButton, { CreateButtonProps } from '../../CreateButton';

interface HeaderProps {
  isListPage: boolean;
  title?: string;
  subtitle?: string;
  isSmallDevice: boolean;
  createButton?: CreateButtonProps;
  exportButton?: ExportButtonProps;
  tabs?: () => React.ReactNode;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  isListPage,
  title,
  subtitle,
  isSmallDevice,
  createButton,
  exportButton,
  tabs,
  children,
}: HeaderProps) => {
  const theme = useTheme();

  return (
    <Box>
      {isListPage ? (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Box sx={{ width: '70%' }}>
              {title && (
                <Typography variant="h1" component="h1" data-testid="title">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="body1" component="h2" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Box>
            <Box>
              {exportButton && <ExportButton {...exportButton} />}
              {createButton && <CreateButton {...createButton} isSmallDevice={isSmallDevice} />}
            </Box>
          </Box>
          {tabs && (
            <Box data-testid="tabs" sx={{ mb: theme.custom.spacing.xs }}>
              {tabs()}
            </Box>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};

export default Header;
