import React from 'react';
import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  ListItemText,
  Box,
  Skeleton,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Typography, CustomTypographyVariant } from '../../';

export enum ListItemDensity {
  NORMAL = 'NORMAL',
  DENSE = 'DENSE',
  SUPER_DENSE = 'SUPER_DENSE',
}

export interface ListItemProps extends Omit<MuiListItemProps, 'dense' | 'component'> {
  /**
   * The first component on the left side of the list item, example uses include: checkbox, avatar, icon
   */
  startComponent?: JSX.Element | JSX.Element[];
  /**
   * The component on the right side of the list item, example uses include: checkbox, radio
   */
  endComponent?: JSX.Element | JSX.Element[];
  /**
   * The main list item text, displayed in a block with secondary text (if provided)
   */
  primaryText?: string;
  /**
   * If true the primary text will not wrap
   */
  primaryTextNoWrap?: boolean;
  /**
   * Smaller text variant displayed below the primary text
   */
  secondaryText?: string;
  /**
   * Defaults to 'NORMAL' and determines the following:
   * - how much padding is applied to the list item
   * - primary text variant
   * - the max height and right padding of the startComponent
   */
  density?: ListItemDensity;
  /**
   * If true a loading skeleton will be displayed for the text elements that have a value that evaluates to true
   */
  isLoading?: boolean;
  /**
   * If provided it will override the default "list-item" test id
   */
  testId?: string;
  /**
   * The string to use a HTML element as the root node, useful when this shouldn't be an "li"
   */
  component?: string;
}

const densityStyles: Record<ListItemDensity, Record<string, SxProps<Theme>>> = {
  [ListItemDensity.NORMAL]: { padding: { py: 2, px: 4 }, startComponent: { maxHeight: '40px', pr: 4, flexShrink: 0 } },
  [ListItemDensity.DENSE]: { padding: { py: 1, px: 4 }, startComponent: { maxHeight: '40px', pr: 4, flexShrink: 0 } },
  [ListItemDensity.SUPER_DENSE]: {
    padding: { py: 1, px: 4 },
    startComponent: { maxHeight: '24px', pr: 3, flexShrink: 0 },
  },
};

const primaryTextVariant: Record<ListItemDensity, CustomTypographyVariant> = {
  [ListItemDensity.NORMAL]: 'body1',
  [ListItemDensity.DENSE]: 'body2',
  [ListItemDensity.SUPER_DENSE]: 'body2',
};

/**
 * Renders a ListItem component
 */
export const ListItem: React.FC<React.PropsWithChildren<ListItemProps>> = ({
  startComponent,
  endComponent,
  primaryText,
  primaryTextNoWrap = false,
  secondaryText,
  density = ListItemDensity.NORMAL,
  isLoading = false,
  testId = 'list-item',
  children,
  ...muiListItemProps
}: ListItemProps) => {
  const sxProps: SxProps<Theme> = {
    ...(densityStyles[density]?.padding ? densityStyles[density]?.padding : {}),
    ...(muiListItemProps?.sx ? muiListItemProps.sx : {}),
  } as SxProps<Theme>; // when the above 2 are combined TS complains, but if either is passed then it's fine :?

  return (
    <MuiListItem sx={sxProps} data-testid={testId} {...muiListItemProps}>
      {/* overflow hidden to ensure we have a visual checkpoint for things that exceed design constraints */}
      {startComponent ? (
        <Box sx={densityStyles[density].startComponent} overflow="hidden" data-testid="start-component-container">
          {startComponent}
        </Box>
      ) : null}
      {(primaryText || secondaryText) && (
        <ListItemText
          sx={{ m: 0 }}
          primary={
            !!primaryText && (
              <Typography
                variant={primaryTextVariant[density]}
                color="textPrimary"
                id={`${testId}-primary-text`}
                noWrap={primaryTextNoWrap}
              >
                {!isLoading ? (
                  primaryText
                ) : (
                  <Skeleton animation="wave" width={80} variant="text" data-testid="primary-text-skeleton" />
                )}
              </Typography>
            )
          }
          secondary={
            !!secondaryText && (
              <Typography
                sx={{
                  overflow: 'hidden',
                  paddingRight: 2,
                  textOverflow: 'ellipsis',
                }}
                variant="body2"
                color="textSecondary"
              >
                {!isLoading ? (
                  secondaryText
                ) : (
                  <Skeleton animation="wave" width={100} variant="text" data-testid="secondary-text-skeleton" />
                )}
              </Typography>
            )
          }
        />
      )}
      {endComponent ? (
        <Box sx={{ ml: 'auto' }} data-testid="end-component-container">
          {endComponent}
        </Box>
      ) : null}
      {children}
    </MuiListItem>
  );
};
