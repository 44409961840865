export enum FeatureFlags {
  PERF_STATS = 'enablePerfStats',
  DISABLE_REGISTRATION = 'disableRegistration',
  ENABLE_REGISTRATION_WITH_TOKEN = 'enableRegistrationWithToken',
  ENABLE_TASK_MESSAGES = 'enableTaskMessages',
  ENABLE_ASK_AGENT = 'enableAskAgent',
  ADMIN_CONFIG = 'enableConfigAdmin',
  NOTIFICATIONS = 'enableNotifications',
  LOGO_FULL_HEIGHT = 'showLogoFullHeight',
  ORDERABLE_PRODUCTS = 'enableOrderableProducts',
  ENABLE_CALL_RECORDS = 'enableCallRecords',
  ENABLE_EXPORT = 'enableExport',
  ENABLE_EDIT_TASK_TYPE = 'enableEditTaskType',
  HIDE_PUBLIC_ASSET_SECURITY = 'hidePublicAssetSecurity',
}
