import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Menu, MenuItem, MenuProps, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { TenantMenuItemsExternalInner } from '@askporter/client-grieg-lyric';
import { IconFolder } from '@askporter/utils';
import { Typography, Icon, IconSize } from '../../../../';
import { disabledNavStyle, IconLink, NavbarLink } from '../../utils';

interface AccountMenuProps extends MenuProps {
  t: (key: string) => string;
  handleSignOut: () => void;
  mainItems: Array<IconLink | NavbarLink>;
  tenantNavButtons: TenantMenuItemsExternalInner[];
}

/** Renders a AccountMenu component */
const AccountMenu: React.FC<React.PropsWithChildren<AccountMenuProps>> = ({
  t,
  handleSignOut,
  mainItems,
  tenantNavButtons = [],
  ...menuProps
}: AccountMenuProps) => {
  const { isDisabled, disabledNavStyles } = disabledNavStyle(location.pathname, '/app/account');

  return (
    <Menu data-testid="menu" keepMounted {...menuProps}>
      <MenuItem
        component={Link}
        to={{ pathname: '/app/account', state: { from: location.pathname } }}
        disabled={isDisabled}
        sx={{ ...disabledNavStyles }}
        data-testid="my-account-btn"
        divider={true}
      >
        <PersonOutlineIcon />
        <Typography ml={2} variant="body2">
          {t('ns.global:header:nav:my_account')}
        </Typography>
      </MenuItem>
      {mainItems.map((item, idx) => {
        if ('href' in item) {
          const { isDisabled, disabledNavStyles } = disabledNavStyle(location.pathname, item?.href);
          return (
            <MenuItem
              component={Link}
              to={item?.href}
              key={`${item?.href}_${item?.translation}`}
              sx={{
                ...disabledNavStyles,
              }}
              disabled={isDisabled}
              divider={item.href === '/app/admin-config' || item.href === '/app/exports'}
            >
              <Icon size={IconSize.MD} folder={item?.iconFolder} />
              <Typography ml={2} variant="body2">
                {t(item?.translation)}
              </Typography>
            </MenuItem>
          );
        } else if ('hamburger' in item) return <Box key={`hamburger_item_${idx}`}>{item?.hamburger}</Box>;
      })}
      {tenantNavButtons?.map((item, idx) => {
        return (
          <MenuItem
            component={'a'}
            href={item?.linkAddress}
            key={idx}
            target={item?.openInNew ? '_blank' : undefined}
            divider={idx === tenantNavButtons.length - 1}
          >
            <Icon size={IconSize.MD} folder={item?.iconPath as IconFolder} />
            <Typography ml={2} variant="body2">
              {item?.linkTitle}
            </Typography>
          </MenuItem>
        );
      })}
      <MenuItem
        aria-label={t('ns.common:sign_out:button')}
        data-testid="sign-out"
        onClick={handleSignOut}
        sx={{ width: '200px' }}
      >
        <LogoutIcon />
        <Typography ml={2} variant="body2">
          {t('ns.global:header:nav:logout')}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
