import React from 'react';
import { AutocompleteRenderGroupParams, List } from '@mui/material';
import { Typography } from '../../Typography';

export const renderGroup = (params: AutocompleteRenderGroupParams): React.ReactNode => {
  const groupKey = params?.group?.toLowerCase().replace(/ /g, '-');
  return (
    <List key={groupKey} data-testid={`${groupKey}-group`}>
      <Typography variant="caption" color="textSecondary" sx={{ p: 2 }}>
        {params.group}
      </Typography>
      <List>{params.children}</List>
    </List>
  );
};
