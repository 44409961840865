import { DayOfWeek } from '@askporter/client-grieg-lyric';
import { DayTimeRangeType } from '../types';
import { parseDayTime } from './parseDayTime';

/**
 * Merges the current DayTime data with the new data selected by the user
 *
 * @param currentState - The current DayTime data
 * @param newState - The new DayTime data selected by the user
 *
 * @returns The merged DayTime data
 */
export const mergeDayTimeState = (
  currentState: Array<DayTimeRangeType>,
  newState: Array<DayTimeRangeType>,
): Array<DayTimeRangeType> => {
  const result: Array<DayTimeRangeType> = [];

  const currentStateParsed = parseDayTime(currentState);
  const newStateParsed = parseDayTime(newState);

  const mergedState = { ...currentStateParsed, ...newStateParsed };

  Object.keys(mergedState).forEach((key: DayOfWeek) => {
    const value = mergedState[key];
    if (value) {
      const entry: DayTimeRangeType = { days: [key], fromTime: value?.fromTime, toTime: value?.toTime };
      result.push(entry);
    }
  });

  return result;
};
