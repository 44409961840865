import React, { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import {
  AssetReadExternalAssetNotes,
  UserReadExternalUserNotes,
  TaskFullTaskNotes,
} from '@askporter/client-grieg-lyric';
import { formatDate } from '@askporter/utils';
import { AvatarSize } from '../../../Avatar';
import { Button } from '../../../Buttons/Button';
import { NewWindowLink } from '../../../NewWindowLink';
import { Typography } from '../../../Typography';
import { UserAvatar } from '../../../UserAvatar';

interface PinnedNotesProps {
  pinnedNotes: Array<UserReadExternalUserNotes | AssetReadExternalAssetNotes | TaskFullTaskNotes>;
  entityType: 'user' | 'asset' | 'task';
  uid: string;
  newNoteForm?: React.ReactNode;
  t?: (key: string) => string;
}

export const PinnedNotes = ({ pinnedNotes, entityType, newNoteForm, uid, t }: PinnedNotesProps): JSX.Element => {
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: newNoteForm && showNewNoteForm && '48px',
        }}
      >
        <Typography variant="h3">{t('ns.inbound_call_triage:pinned_notes')}</Typography>
        {newNoteForm && !showNewNoteForm && (
          <Button onClick={() => setShowNewNoteForm(true)}>{`${t('ns.inbound_call_triage:new_note')} +`}</Button>
        )}
      </Box>
      {newNoteForm && <Collapse in={showNewNoteForm}>{newNoteForm}</Collapse>}
      {pinnedNotes.map((note) => {
        return (
          <Box key={note.uid} sx={{ width: '100%', p: 2 }}>
            <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
              <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
                {note.body}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 4,
                minWidth: 0,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <UserAvatar
                givenName={note?.author?.givenName}
                familyName={note?.author?.familyName}
                imagePath={note?.author?.profilePhoto?.filePath}
                userRole={note?.author?.roleSlug}
                avatarSize={AvatarSize.XS}
              />
              <Typography noWrap variant="body2" sx={{ pr: 2 }}>
                {`${note?.author?.givenName} ${note?.author?.familyName}`}
              </Typography>
              <Typography noWrap variant="body2" color="textSecondary">
                {formatDate(note?.changeSummary?.createdAt, 'dd MMM yy HH:mm') || ''}
              </Typography>
            </Box>
          </Box>
        );
      })}

      {(entityType === 'user' || entityType === 'task') && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <NewWindowLink href={entityType === 'user' ? `/app/people/users/${uid}/notes` : `/app/tasks/${uid}/notes`}>
            {t('ns.inbound_call_triage:open_all_notes')}
          </NewWindowLink>
        </Box>
      )}
    </>
  );
};
