import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from '@askporter/api-client';
import {
  AssetExportCreate,
  ErrorModel,
  OrgExportCreate,
  TaskExportCreate,
  TeamExportCreate,
  UserExportCreate,
  TaskExportReadExternal,
  AssetExportReadExternal,
  UserExportReadExternal,
  OrgExportReadExternal,
  TeamExportReadExternal,
} from '@askporter/client-grieg-lyric';
import { API } from '../../../utils/api';

export const useExportMutations = () => {
  return {
    taskExportMutation: useMutation<AxiosResponse<TaskExportReadExternal>, AxiosError<ErrorModel>, TaskExportCreate>(
      (data: TaskExportCreate) => API().post({ path: `exports/task`, payload: data }),
    ),
    assetExportMutation: useMutation<AxiosResponse<AssetExportReadExternal>, AxiosError<ErrorModel>, AssetExportCreate>(
      (data: AssetExportCreate) => API().post({ path: `exports/asset`, payload: data }),
    ),
    userExportMutation: useMutation<AxiosResponse<UserExportReadExternal>, AxiosError<ErrorModel>, UserExportCreate>(
      (data: UserExportCreate) => API().post({ path: `exports/user`, payload: data }),
    ),
    orgExportMutation: useMutation<AxiosResponse<OrgExportReadExternal>, AxiosError<ErrorModel>, OrgExportCreate>(
      (data: OrgExportCreate) => API().post({ path: `exports/org`, payload: data }),
    ),
    teamExportMutation: useMutation<AxiosResponse<TeamExportReadExternal>, AxiosError<ErrorModel>, TeamExportCreate>(
      (data: TeamExportCreate) => API().post({ path: `exports/team`, payload: data }),
    ),
  };
};
