import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

type GetTaskEscalationAlertPropsReturn = {
  escalationSeverity: 'warning' | 'error';
  escalationIcon: React.JSX.Element;
  escalationLevelText: string;
};

export const getTaskEscalationAlertProps = ({
  escalationStatus,
  t,
}: {
  escalationStatus: 'L1' | 'L2' | 'DONE_L1' | 'DONE_L2' | 'NONE';
  t: (key: string) => string;
}): GetTaskEscalationAlertPropsReturn => {
  switch (escalationStatus) {
    case 'L1':
      return {
        escalationSeverity: 'warning',
        escalationIcon: <KeyboardArrowUpIcon />,
        escalationLevelText: t('task_list:task_card:escalation_level_1'),
      };
    case 'L2':
      return {
        escalationSeverity: 'error',
        escalationIcon: <KeyboardDoubleArrowUpIcon />,
        escalationLevelText: t('task_list:task_card:escalation_level_2'),
      };
    default:
      return null;
  }
};
