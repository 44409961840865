import { TenantConfig, RoleSlug } from '@askporter/client-grieg-lyric';
import { CU_FeatureFlags, CU_UserFeatureFlags } from '@askporter/utils';

type FullNavButton = {
  roleSlug: RoleSlug;
  requiredRoles: RoleSlug[];
  userFeatureFlags?: never;
  requiredUserFeatureFlag?: never;
};

type IconNavButton = {
  userFeatureFlags: string[];
  requiredUserFeatureFlag: CU_UserFeatureFlags;
  roleSlug?: never;
  requiredRoles?: never;
};

type CheckIfNavButtonIsAllowedProps = {
  featureFlags: TenantConfig['featureFlags'];
  requiredFeatureFlag: CU_FeatureFlags;
} & (FullNavButton | IconNavButton);

/**
 * A function to check if a navigation button is allowed to be displayed.
 *
 * Note: you should pass undefined to the requiredFeatureFlag, requiredRoles and requiredUserFeatureFlag if you don't want to check for them
 * @param featureFlags - The feature flags of the user
 * @param userFeatureFlags - The user feature flags
 * @param roleSlug - The role of the user
 * @param requiredFeatureFlag - The required feature flag
 * @param requiredRoles - The required roles
 * @param requiredUserFeatureFlag - The required user feature flag
 * @returns boolean
 */
export const checkIfNavButtonIsAllowed = ({
  featureFlags,
  requiredFeatureFlag,
  ...rest
}: CheckIfNavButtonIsAllowedProps) => {
  const { roleSlug, requiredRoles, userFeatureFlags, requiredUserFeatureFlag } = {
    roleSlug: undefined,
    requiredRoles: [] as RoleSlug[],
    userFeatureFlags: [] as string[],
    requiredUserFeatureFlag: undefined,
    ...rest,
  };

  if (requiredFeatureFlag && !featureFlags?.[requiredFeatureFlag]) return false;
  if (requiredRoles?.length > 0 && !requiredRoles.includes(roleSlug)) return false;
  if (requiredUserFeatureFlag && !userFeatureFlags?.includes(requiredUserFeatureFlag)) return false;

  return true;
};
