import {
  TaskExportReadExternalFieldsEnum,
  OrgExportReadExternalFieldsEnum,
  AssetExportReadExternalFieldsEnum,
  UserExportReadExternalFieldsEnum,
  RoleSlug,
} from '@askporter/client-grieg-lyric';
import { ManagerRoles, SupplierRoles, ServiceUserRoles } from '@askporter/utils';
import { CheckboxEssentialFields } from '../types';

// Roles and fields detailed here https://askproducts.atlassian.net/browse/AP-2545

export const exportTasksCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.TASK_ESSENTIALS]: [
    ...ManagerRoles,
    RoleSlug.SuppSpr, // The super supplier role
    ...SupplierRoles,
    RoleSlug.SvcusrSpr, // The super service user role
    ...ServiceUserRoles,
  ],
  [TaskExportReadExternalFieldsEnum.TaskResources]: [
    ...ManagerRoles,
    RoleSlug.SuppSpr,
    ...SupplierRoles,
    RoleSlug.SvcusrSpr,
    ...ServiceUserRoles,
  ],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'TASK_ESSENTIALS'> | TaskExportReadExternalFieldsEnum,
  RoleSlug[]
>;

export const exportAssetsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.ASSET_ESSENTIALS]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  [AssetExportReadExternalFieldsEnum.AssetDetails]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  [AssetExportReadExternalFieldsEnum.AssetSettings]: [...ManagerRoles],
  [AssetExportReadExternalFieldsEnum.RegistrationToken]: [...ManagerRoles, RoleSlug.SvcusrSpr],
  [AssetExportReadExternalFieldsEnum.OnsiteAssistant]: [...ManagerRoles],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'ASSET_ESSENTIALS'> | AssetExportReadExternalFieldsEnum,
  RoleSlug[]
>;

export const exportUsersCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.USER_ESSENTIALS]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  [UserExportReadExternalFieldsEnum.AccountSettings]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  [UserExportReadExternalFieldsEnum.LinkedToUser]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'USER_ESSENTIALS'> | UserExportReadExternalFieldsEnum,
  RoleSlug[]
>;

export const exportOrgsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.ORG_ESSENTIALS]: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  [OrgExportReadExternalFieldsEnum.RegistrationToken]: [...ManagerRoles, RoleSlug.SvcusrSpr],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'ORG_ESSENTIALS'> | OrgExportReadExternalFieldsEnum,
  RoleSlug[]
>;

export const exportTeamsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.TEAM_ESSENTIALS]: [...ManagerRoles],
} as Record<Extract<keyof typeof CheckboxEssentialFields, 'TEAM_ESSENTIALS'>, RoleSlug[]>;

export const exportFieldsPermissionMapping = {
  tasks: exportTasksCheckboxFieldsPermissionMapping,
  assets: exportAssetsCheckboxFieldsPermissionMapping,
  users: exportUsersCheckboxFieldsPermissionMapping,
  orgs: exportOrgsCheckboxFieldsPermissionMapping,
  teams: exportTeamsCheckboxFieldsPermissionMapping,
};
