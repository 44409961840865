import { CallRecordSearchResultExternal, UserRefHydrated } from '@askporter/client-grieg-lyric';

/**
 * Given a list of agents, this function will return the main agent, this is determined by the following logic:
 * 1. If there is only one agent, return that agent
 * 2. If there are multiple agents, return the agent with the role of CALL_AGENT
 * 3. If there are multiple agents and none have the role of CALL_AGENT, return the agent with the longest duration
 */
export const getMainAgent = (agents: CallRecordSearchResultExternal['agents'] = []): UserRefHydrated | undefined => {
  if (agents.length === 0) {
    return undefined;
  } else if (agents.length === 1) {
    return agents[0]?.user;
  } else {
    const callAgent = agents.find((agent) => agent.callRole === 'CALL_AGENT');
    if (callAgent) return callAgent?.user;

    const agentWithLongestDuration = agents.reduce((prev, curr) => {
      const { duration: prevDuration = 0 } = prev;
      const { duration: currDuration = 0 } = curr;

      return prevDuration > currDuration ? prev : curr;
    });

    return agentWithLongestDuration?.user;
  }
};
