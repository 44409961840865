import React from 'react';
import { Box } from '@mui/material';
import { OrgTypeSlug } from '@askporter/client-grieg-lyric';
import { Avatar } from '../Avatar';
import Icon, { IconProps, IconSize } from '../Icon';

export interface TeamAvatarProps {
  /**
   * title that goes beside Avatar
   */
  title: string;
  /**
   * optional - url path to the user's profile photo
   */
  avatarPhoto?: string;
  /**
   * optional - role for organisation
   */
  role?: OrgTypeSlug;
  /**
   * optional - the data-testid string for the Avatar
   */
  dataTestId?: string;
  /**
   * optional - boolean to indicate if the avatar is loading
   */
  isLoading?: boolean;
  /**
   * optional - the user role title shown next to user role icon
   */
  roleTitle?: string;
  /**
   * optional - text to be shown in Avatar Icon if no image
   */
  avatarIconText?: string;
}

const orgIconMap: Record<string, Partial<IconProps>> = {
  [OrgTypeSlug.ServiceUser]: { folder: 'people/people-types/07-service-user' },
  [OrgTypeSlug.Supplier]: { folder: 'people/people-types/09-supplier' },
  [OrgTypeSlug.Owner]: { folder: 'people/people-types/05-owner' },
};

/**
 * TeamAvatar that composes Avatar for Teams or Orgs
 */
export const TeamAvatar: React.FC<React.PropsWithChildren<TeamAvatarProps>> = ({
  title,
  avatarPhoto,
  role,
  roleTitle,
  dataTestId = 'org-avatar',
  isLoading,
  avatarIconText,
  ...teamAvatarProps
}: TeamAvatarProps): JSX.Element => {
  const iconProps: Partial<IconProps> = role ? orgIconMap[role] : { folder: 'system/08-organisations' };

  return (
    <Avatar
      dataTestId={dataTestId}
      altText={title}
      isLoading={isLoading}
      avatar={{
        mode: 'image',
        variant: 'rounded',
        imagePath: avatarPhoto,
        fallback: avatarIconText,
        avatarText: {
          mode: 'header',
          subTitle: roleTitle && roleTitle,
          subTitleIcon: roleTitle && (
            <Box sx={{ mr: 1 }}>
              <Icon {...iconProps} size={IconSize.SM} fillColor="#0000008A" />
            </Box>
          ),
          title: title,
        },
      }}
      {...teamAvatarProps}
    />
  );
};
