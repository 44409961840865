import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { ISubmitEvent } from '@rjsf/core';
import { useParams } from 'react-router-dom';
import {
  AssetReadExternal,
  AssetTypeReadExternal,
  AssetTypeReadExternalExtMetadataConfig,
  OrgReadExternal,
  OrgTypeExtMetadataReadExternal,
  OrgUpdate,
  ProfileReadExternal,
  RoleExtMetadataReadExternal,
  OrgTypeExtMetadataReadExternalExtMetadataConfig,
  RoleSlug,
  TenantLanguage,
  UserReadExternal,
} from '@askporter/client-grieg-lyric';
import {
  generateJSONSchema,
  generateUISchema,
  transformExtendedMetadata,
  SimplifiedIAPIClient,
} from '@askporter/utils';
import { useAutosave } from '../../hooks';
import { dataTypeTransformMap, RJSF } from '../../RJSF';
import { AutoSaveStatus } from '../AutoSaveStatusText';
import { BasicPageTemplate } from '../BasicPageTemplate';
import { Typography } from '../Typography';

interface ExtendedMetadataProps {
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  data: ProfileReadExternal | OrgReadExternal | UserReadExternal | AssetReadExternal;
  extendedMetadataConfig: RoleExtMetadataReadExternal | OrgTypeExtMetadataReadExternal | AssetTypeReadExternal;
  updateDataMutation: {
    mutateAsync: (payload: ProfileReadExternal | OrgUpdate | UserReadExternal | AssetReadExternal) => Promise<unknown>;
    reset: () => void;
  };
  currentUserRole: RoleSlug;
  API: () => SimplifiedIAPIClient;
  tenantLanguages: TenantLanguage[];
  forceReadOnly?: boolean;
}

/**
 * Renders the Extended metadata page
 *
 * @param t - Translation function
 * @param isSmallDevice - true / false flag whether we are above the 'sm' (768px) breakpoint
 * @param data - Entity data
 * @param extendedMetadataConfig - The extended metadata config
 * @param updateDataMutation - Mutation used to update the data
 * @param currentUserRole - The role of the logged in user
 */

export const ExtendedMetadata: React.FC<React.PropsWithChildren<ExtendedMetadataProps>> = ({
  isSmallDevice,
  data,
  extendedMetadataConfig,
  updateDataMutation,
  currentUserRole,
  t,
  API,
  tenantLanguages,
  forceReadOnly,
}: ExtendedMetadataProps) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { uid } = useParams<{ uid: string }>();

  const detailsExtMetadata = extendedMetadataConfig?.extMetadataConfig
    .map(
      (c: AssetTypeReadExternalExtMetadataConfig | OrgTypeExtMetadataReadExternalExtMetadataConfig) =>
        c.extMetadataGroupConfig,
    )
    .filter((m) => m.uid === uid);

  const schemaKey = detailsExtMetadata?.[0]?.jsonElementName;
  const formTitle = detailsExtMetadata?.[0]?.displayName;

  const transformedFormData = transformExtendedMetadata(
    data?.extendedMetadata,
    extendedMetadataConfig?.extMetadataConfig,
  );
  const [formData, setFormData] = useState<any>(transformedFormData);

  const jsonSchema = generateJSONSchema(detailsExtMetadata, formData, currentUserRole);
  const uiSchema = generateUISchema(detailsExtMetadata, currentUserRole, dataTypeTransformMap);

  useEffect(() => {
    if (data && data.extendedMetadata && data.extendedMetadata[schemaKey]) {
      setFormData({ [schemaKey]: data.extendedMetadata[schemaKey] });
    } else {
      setFormData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, data]);

  const handleSubmit = async (e: ISubmitEvent<{ formData: Record<string, unknown> }>, fieldId: string) => {
    try {
      await updateDataMutation.mutateAsync({ extendedMetadata: { ...e.formData } });
      return { fieldId, autoSaveStatus: AutoSaveStatus.SAVED };
    } catch (error) {
      return { fieldId, autoSaveStatus: AutoSaveStatus.ERROR };
    }
  };

  const { formProps } = useAutosave(submitButtonRef, handleSubmit);

  return (
    <BasicPageTemplate isSmallDevice={isSmallDevice} testId="extended-metadata" t={t}>
      {!isSmallDevice && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3">{formTitle}</Typography>
        </Box>
      )}
      {formData && (
        <RJSF
          id="ext-metadata-form"
          schema={jsonSchema}
          formData={formData}
          uiSchema={uiSchema}
          onChange={({ formData }) => {
            updateDataMutation.reset();
            setFormData(formData);
          }}
          showErrorList={false}
          noHtml5Validate={true}
          sx={
            !isSmallDevice
              ? {
                  '& fieldset > legend': {
                    display: 'none',
                  },
                }
              : {}
          }
          formContext={{ t, API, tenantLanguages }}
          {...(forceReadOnly ? { readOnly: true } : {})}
          {...formProps}
        >
          <button style={{ display: 'none' }} ref={submitButtonRef} />
        </RJSF>
      )}
    </BasicPageTemplate>
  );
};
