import React, { useState } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { Box, BoxProps, InputAdornment, IconButton, Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { AxiosError, IAPIClient } from '@askporter/api-client';
import { SearchAutocompleteResult, ErrorModel, SearchAutocompleteRequest } from '@askporter/client-grieg-lyric';
import { useDebounce } from '@askporter/utils';

type AutocompleteUnion =
  | {
      autocompleteEnabled: true;
      /**
       * fires when user changes input value
       */
      onChange?: never;
      /**
       * The API client
       */
      API: () => IAPIClient;
      /**
       * resourceName for the suggestions API
       */
      resourceName:
        | 'tasks'
        | 'assets'
        | 'users'
        | 'teams'
        | 'orgs'
        | 'people'
        | 'tags'
        | 'products'
        | 'product-catalogues';
    }
  | {
      autocompleteEnabled: false;
      onChange: (val: string) => void;
      API?: never;
      resourceName?: never;
    };

export type SearchBoxProps = {
  /**
   * input label text
   */
  label: string;
  /**
   * fires when user hits enter or clicks the search icon
   */
  onSubmit: (searchBoxValue: string) => void;
  /**
   * starting value of the search box
   */
  startValue?: string;
  /**
   * search button aria label text
   */
  searchButtonAria?: string;
  /**
   * handles the differences between figma designs and older uses of this component
   */
  disableSelfSpacing?: boolean;
  /**
   * passes props to top the wrapper box
   */
  wrapperBoxProps?: BoxProps;
} & AutocompleteUnion;

export const SearchBox: React.FC<React.PropsWithChildren<SearchBoxProps>> = ({
  label,
  onSubmit,
  startValue,
  searchButtonAria,
  disableSelfSpacing,
  wrapperBoxProps,
  autocompleteEnabled,
  API,
  resourceName,
  onChange,
}: SearchBoxProps) => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 700);
  const [hasFocus, setHasFocus] = useState(false);
  const shrinkLabel = hasFocus || searchText !== '' ? true : false;

  const payload: SearchAutocompleteRequest = { freeText: debouncedSearchText };
  const { data } = useQuery<{ data: SearchAutocompleteResult }, AxiosError<ErrorModel>>(
    [`autocomplete/${resourceName}`, payload],
    () => API().post({ path: `autocomplete/${resourceName}`, payload }),
    {
      enabled: autocompleteEnabled && debouncedSearchText?.length > 2,
    },
  );

  return (
    <Box {...(disableSelfSpacing ? {} : { px: 6, pb: 6 })} {...wrapperBoxProps}>
      <Autocomplete
        options={data?.data?.suggestions || []}
        freeSolo
        fullWidth
        value={startValue}
        inputValue={searchText}
        onChange={(_, value) => onSubmit(value || '')}
        onInputChange={(_, value) => {
          setSearchText(value || '');
          onChange && onChange(value || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: shrinkLabel,
              sx: {
                pl: shrinkLabel ? 0 : 8,
              },
            }}
            InputProps={{
              ...params.InputProps,
              notched: shrinkLabel,
              onFocus: () => setHasFocus(true),
              onBlur: () => setHasFocus(false),
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label={searchButtonAria} edge="start" onClick={() => onSubmit(searchText)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: undefined,
            }}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'autocomplete-search-box-input',
            }}
            sx={{
              backgroundColor: '#fff',
              mt: 0,
            }}
          />
        )}
      />
    </Box>
  );
};
