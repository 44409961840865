import { RoleSlug } from '@askporter/client-grieg-lyric';
import { AgentRoles, ManagerRoles, ServiceUserRoles } from '../../types';
import {
  assetLinkedTasksFilters,
  orgLinkedTasksFilters,
  taskListFilters,
  teamLinkedTasksFilters,
  userLinkedTasksFilters,
} from './taskFilters';
import { userProfilePagesVisibility } from './userProfilePagesVisibility';

/*
Permissions mapping object which should be kept fairly flat, suggested level would be page. However cases like "filters"
that span multiple pages are probably safer as typed objects and spread in.

For traceability, if the mapping does not come from a golden source (e.g. the confluence page then referencing ticket)
*/
export const permissionMapping = {
  asset: {
    assetLinkedTasksFilters,
    linkTask: ManagerRoles,
    viewLinkedTask: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr], // JIRA: AP-1286
    removeLinkedTask: ManagerRoles, // JIRA: AP-1286
    linkPpl: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
    canViewNotesList: ManagerRoles,
    canAddNote: ManagerRoles,
    canLinkChildAsset: ManagerRoles,
    canUnlinkChildAsset: ManagerRoles,
    canViewQuickLinksPage: ManagerRoles,
    canEditCatalogue: [RoleSlug.PlatAdmin, RoleSlug.ClientAdmin],
    canViewOfferingsPage: ManagerRoles,
  },
  assetList: {
    createAssetOrgIsRequired: [RoleSlug.Owner, RoleSlug.OwnerSpr], // fe issue: 1027
    canSeeExtMetaFilters: ManagerRoles,
    hasExportPermission: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr], // https://askproducts.atlassian.net/browse/AP-2542
  },
  org: {
    orgLinkedTasksFilters,
    editOrg: [...AgentRoles, RoleSlug.OwnerSpr, RoleSlug.SvcusrSpr, RoleSlug.SuppSpr],
    linkTask: ManagerRoles, // JIRA: AP-1285
    viewLinkedTask: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr], // JIRA: AP-1286
    removeLinkedTask: ManagerRoles, // JIRA: AP-1286
    linkedTasksList: {
      hasExportPermission: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr], // https://askproducts.atlassian.net/browse/AP-2542
    },
  },
  orgList: {
    canSeeExtMetaFilters: ManagerRoles,
    canClickOrgCard: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.SuppSpr],
    canCreateOrg: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    hasExportPermission: [...ManagerRoles, RoleSlug.SvcusrSpr], // https://askproducts.atlassian.net/browse/AP-2542
  },
  messageList: {
    canSelectParticipants: ManagerRoles,
    canDisableReplies: ManagerRoles,
    canRequestUpload: ManagerRoles,
  },
  selectMessageParticipants: {
    canBeSelectedOnOrgAndTeam: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  },
  thread: {
    canDisableReplies: ManagerRoles,
    canRequestUpload: ManagerRoles,
  },
  callRecord: {
    canSeeAllInfo: ManagerRoles,
  },
  peopleList: {
    inviteUserOrgIsRequired: [RoleSlug.SvcusrSpr, RoleSlug.SuppSpr, RoleSlug.Owner, RoleSlug.OwnerSpr], // fe issue: 1028
    canSeeVipFlag: ManagerRoles,
  },
  taskList: {
    createTaskOrgIsRequired: [RoleSlug.Owner, RoleSlug.OwnerSpr], // fe issue: 1026
    taskListFilters,
  },
  taskDetails: {
    canUpdateInitiator: ManagerRoles,
    canViewInitiator: [RoleSlug.Svcusr, RoleSlug.SvcusrSpr], // https://askproducts.atlassian.net/browse/AP-2799
    canUpdatePrincipalAsset: ManagerRoles,
    canViewPrincipalAsset: [RoleSlug.Supp, RoleSlug.SuppSpr, RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
    createUnregUserOrgRequired: [RoleSlug.Owner, RoleSlug.OwnerSpr],
    canViewLinks: ManagerRoles,
    canViewPrivacyInfo: ServiceUserRoles,
    canChangeOffering: ManagerRoles,
    canChangeTaskType: ManagerRoles,
    canSeeResolution: ManagerRoles,
  },
  taskNav: {
    shouldAlertIfNoAssets: ManagerRoles,
    shouldAlertIfNoPeople: ManagerRoles,
    shouldSeeMessageManager: [RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
  },
  taskNotes: {
    canViewNotesList: ManagerRoles,
    canAddNote: ManagerRoles,
  },
  team: {
    inviteUserOrgIsRequired: [RoleSlug.SvcusrSpr, RoleSlug.SuppSpr, RoleSlug.Owner, RoleSlug.OwnerSpr], // fe issue: 1028
    editTeam: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    linkTask: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    linkAsset: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    removeLinkedTask: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    removeLinkedAsset: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    teamLinkedTasksFilters,
    linkedTasksList: {
      hasExportPermission: ManagerRoles, // https://askproducts.atlassian.net/browse/AP-2542
    },
  },
  teamList: {
    hasExportPermission: ManagerRoles, // https://askproducts.atlassian.net/browse/AP-2542
    hasPermissionToCreateTeam: [RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
  },
  user: {
    userLinkedTasksFilters,
    userProfilePagesVisibility,
    linkTask: ManagerRoles,
    viewLinkedTask: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr], // JIRA: AP-1286
    removeLinkedTask: ManagerRoles, // JIRA: AP-1286
    viewLinkedAsset: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
    removeLinkedAsset: ManagerRoles,
    addLinkedAsset: ManagerRoles,
    isManager: ManagerRoles,
    isSuperServiceUser: RoleSlug.SvcusrSpr,
    isSuperSuppliers: RoleSlug.SuppSpr,
    canViewNotesList: ManagerRoles,
    canAddNote: ManagerRoles,
    linkedTasksList: {
      hasExportPermission: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr], // https://askproducts.atlassian.net/browse/AP-2542
    },
  },
  userList: {
    canSeeExtMetaFilters: ManagerRoles,
    hasExportPermission: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr], //https://askproducts.atlassian.net/browse/AP-2542
  },
  workOrder: {
    details: {
      canSeeAcceptedBy: ManagerRoles,
      canSeeUploadedQuote: ManagerRoles,
      canSeeSupplierComment: ManagerRoles,
      canSeeApprovedBy: ManagerRoles,
      canGetAnotherQuote: ManagerRoles,
      canSeeAllWhenSchedulingPending: ManagerRoles,
      canSeeRejectedQuoteDetails: ManagerRoles,
      canSeeDeclinedWorkOrderDetails: ManagerRoles,
    },
    definition: {
      canSeeApprover: ManagerRoles,
      canSeeSupplier: ManagerRoles,
      canSubmitForm: ManagerRoles,
      canEdit: ManagerRoles, // this is a high level rule, which may be superseded by the activity status in Definition
      canBeApproverIfOrgMember: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr], // directly linked users also
    },
  },
};
