import { RoleSlug } from '@askporter/client-grieg-lyric';

export enum FullUserRole {
  SVCUSR = 'common:people:service_user',
  SVCUSR_SPR = 'common:people:super_service_user',
  AGENT = 'common:people:agent',
  AGENT_SPR = 'common:people:super_agent',
  CLIENT_ADMIN = 'common:people:client_admin',
  PLAT_ADMIN = 'common:people:platform_admin',
  OWNER = 'common:people:owner',
  OWNER_SPR = 'common:people:super_owner',
  SUPP = 'common:people:supplier',
  SUPP_SPR = 'common:people:super_supplier',
  UNREG = 'common:people:unreg',
}

export const ManagerRoles: Partial<RoleSlug[]> = [
  RoleSlug.Agent,
  RoleSlug.AgentSpr,
  RoleSlug.ClientAdmin,
  RoleSlug.PlatAdmin,
  RoleSlug.Owner,
  RoleSlug.OwnerSpr,
];

export const ServiceUserRoles = [RoleSlug.Svcusr, RoleSlug.SvcusrSpr, RoleSlug.Unreg];

export const SupplierRoles: Partial<RoleSlug[]> = [RoleSlug.Supp, RoleSlug.SuppSpr];

export const AgentRoles = [RoleSlug.Agent, RoleSlug.AgentSpr, RoleSlug.ClientAdmin, RoleSlug.PlatAdmin];

export const AllRoles = [RoleSlug.Svcusr, RoleSlug.SvcusrSpr, ...ManagerRoles, RoleSlug.Supp, RoleSlug.SuppSpr];
