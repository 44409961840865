import React, { LegacyRef } from 'react';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import { Timespan as MobiscrollTimeSpanBase, MbscTimespanOptions } from '@mobiscroll/react4';
import { TextField } from '@mui/material';
import { Typography } from '../Typography';

export interface TimeSpanProps extends Omit<MbscTimespanOptions, 'onChange' | 'value'> {
  disabled?: boolean;
  readOnly?: boolean;
  value: number;
  onChange?: (value: number, targetNode: HTMLElement) => void;
}

/**
 * Due to React 18 types update children needs to be explicitly typed
 * Since mobiscroll doesn't have a type for children, we need to cast it to any
 */
const MobiscrollTimeSpan = MobiscrollTimeSpanBase as unknown as React.FC<React.PropsWithChildren<any>>;

export const Timespan: React.FC<React.PropsWithChildren<TimeSpanProps>> = React.forwardRef(
  ({ disabled = false, readOnly = false, onChange, children, value, ...props }, ref) => {
    const timespanRef = React.useRef(null);
    const [loaded, setLoaded] = React.useState(false);

    // because we're accessing the timespan instance for readonly, and the ref is only set when the input is attached,
    // we need to trigger a rerender when it loads, so that readonly works
    React.useEffect(() => {
      if (timespanRef.current) {
        setLoaded(true);
      }
    }, [loaded, timespanRef]);

    // milliseconds to seconds, as we're expecting seconds
    const onClose: MbscTimespanOptions['onChange'] = (_, instance) => {
      onChange(instance.getVal() / 1000, instance?.element);
    };

    return (
      <div key={`${loaded}`}>
        <MobiscrollTimeSpan
          ref={timespanRef}
          useShortLabels={true}
          showOverlay={false}
          display="bubble"
          theme="material"
          wheelOrder="ddhhii"
          buttons={[]}
          rows={7}
          {...props}
          // convert seconds to milliseconds, then cast to string for mobiscroll
          value={`${value * 1000}`}
          onClose={onClose}
        >
          {readOnly ? (
            <div ref={ref as LegacyRef<HTMLDivElement>} />
          ) : (
            children || <TextField inputRef={ref} variant="outlined" disabled={disabled} />
          )}
        </MobiscrollTimeSpan>
        {readOnly && <Typography variant="body1">{timespanRef.current?.instance._tempValue}</Typography>}
      </div>
    );
  },
);

Timespan.displayName = 'Timespan';
