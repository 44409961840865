import React from 'react';
import { Typography } from '../../';

const NoResults = ({ t }: { t: (key: string) => string }): JSX.Element => (
  <>
    <Typography>{`${t('ns.common:no_results')}`}</Typography>
    <Typography>{`${t('ns.common:try_again')}`}</Typography>
  </>
);

export { NoResults };
