import { TenantConfig } from '@askporter/client-grieg-lyric';
import { ConfigErrors } from '../types';
import defaultConfig from './defaults';

/**
 * Attempt to resolve errors
 * @param errors an object containing config errors
 */
const remediateErrors = (errors: ConfigErrors, data: TenantConfig): void => {
  // Handle nested error cases
  if (errors.tenantDisplayPalette) {
    data.tenantDisplay.palette = defaultConfig.tenantDisplay.palette;
    errors.tenantDisplayPalette.remediated = `defaulted palette values`;
  }

  if (errors.tenantDisplayLogos) {
    data.tenantDisplay.logos = defaultConfig.tenantDisplay.logos;
    errors.tenantDisplayLogos.remediated = `defaulted logos values`;
  }

  // Generic handling of L1 keys where we can simply assign  default values
  for (const k in errors) {
    const key = k as keyof TenantConfig;

    // Only attempt to default if a remediated state string has not yet been set
    if (errors[key].remediated === undefined) {
      try {
        if (!defaultConfig[key]) throw Error(`No default values available for ${key}`);
        Object.assign(data, { [key]: defaultConfig[key] });

        errors[key].remediated = `defaulted ${key} values`;
      } catch (err) {
        errors[key].remediated = `encountered error while remediating. ${err}`;
      }
    }
  }
};

export default remediateErrors;
