import { AxiosResponse } from 'axios';
import { RelationshipLabel } from '@askporter/client-grieg-lyric';
import { MutationResultItem, DeleteRelationshipMutation } from '../types';

/**
 * Generic function for deleting a link between parties with the provided relationship label
 *
 * @param aParty - the a party URN
 * @param bParty - the b party URN
 * @param relLabel - the relationship label
 * @param deleteRelationship - the react-query delete mutation function
 *
 */
export const relationshipDelete = (
  aParty: string,
  bParty: string,
  relationshipLabel: RelationshipLabel,
  deleteRelationship: DeleteRelationshipMutation,
): Promise<MutationResultItem<AxiosResponse>> => {
  return new Promise((resolve) => {
    deleteRelationship.mutate(
      {
        relationshipKey: {
          aParty: aParty,
          relationshipLabel: relationshipLabel,
          bParty: bParty,
        },
      },
      {
        onSuccess: (data: MutationResultItem<AxiosResponse<any>>) => resolve(data),
        onError: (error: unknown) => resolve({ error, aParty, relLabel: relationshipLabel, bParty }),
      },
    );
  });
};
