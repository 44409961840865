import React from 'react';
import { Box, useTheme } from '@mui/material';
import { match, NavLink, RouteComponentProps } from 'react-router-dom';
import { Icon, IconButton, IconSize, Typography } from '../../../../';
import { NavbarLink } from '../../utils';

interface FullNavBarLinkProps {
  fullNavButtons: Array<NavbarLink>;
  t: (key: string) => string;
}

/** Renders a the Full Nav Bar links */
const FullNavBarLink: React.FC<React.PropsWithChildren<FullNavBarLinkProps>> = ({
  fullNavButtons,
  t,
}: FullNavBarLinkProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignSelf="flex-end"
      height="100%"
      data-testid="desktop-full-nav-bar"
      sx={{ ml: theme.custom.spacing.lg }}
    >
      {fullNavButtons.map((button) => (
        <IconButton
          key={button.href}
          disableRipple
          sx={{
            px: 4,
            borderRadius: '0',
            '&:focus': {
              outline: `2px solid ${theme.palette.primary.main}`,
              outlineOffset: '-2px',
            },
            '&:hover': {
              backgroundColor: theme.palette.background.default,
            },
            '&.active': {
              borderBottom: `3px solid ${theme.palette.primary.main}`,
            },
            height: '100%',
          }}
          color="inherit"
          aria-label={t(`${button.translation}`)}
          component={NavLink}
          to={button.href}
          isActive={(match: match, location: RouteComponentProps['location']) => {
            if (button?.matchExact) return match?.isExact;
            if (button?.matchPath && location.pathname?.match(button?.matchPath)) return true;
            if (match) return true;
          }}
        >
          <Icon size={IconSize.MD} folder={button.iconFolder} />
          <Typography variant="body1" ml={2}>
            {t(`${button.translation}`)}
          </Typography>
        </IconButton>
      ))}
    </Box>
  );
};

export default FullNavBarLink;
