import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';
import { RoleSlug } from '@askporter/client-grieg-lyric';
import { IconFolder, CU_UserFeatureFlags, CU_FeatureFlags, AllRoles, ManagerRoles } from '@askporter/utils';
import { Icon, IconSize, Typography } from '../../../';

// The full nav buttons are header items that can be displayed as a full button (with text)
export const fullNavButtons = ['assistant', 'tasks', 'assets', 'people', 'performance-stats'] as const;
export type FullNavButton = typeof fullNavButtons[number];

// The menu nav items are the header items that are only displayed in the hamburger menu
export const menuOnlyNavButtons = ['admin-config', 'exports'] as const;
export type MenuOnlyNavButton = typeof menuOnlyNavButtons[number];

// The icon nav buttons are header items that are only be displayed as an icon.
export const iconNavButtons = ['askAgent', 'notifications'] as const;
export type IconNavButton = typeof iconNavButtons[number];

export interface IconLink {
  /** The standard view for an icon link, i.e. when it is displayed in the navbar */
  standard: React.ReactNode;
  /** The menu item when the link is displayed in the hamburger menu */
  hamburger?: React.ReactNode;
  /** The tenant level feature flag required to see this option  */
  requiredFeatureFlag?: CU_FeatureFlags;
  /** The user feature flag required to see this option  */
  requiredUserFeatureFlag?: CU_UserFeatureFlags;
}

export const createIconNavButtonLinks = (t: (key: string) => string): Record<IconNavButton, IconLink> => {
  return {
    askAgent: {
      standard: (
        <Box height="100%" width="50px" display="flex" justifyContent="center" alignItems="center">
          <a href="/aa/index.html" target="__blank" data-testid="nav-item-askagent">
            <Icon folder="people/people-types/03-agent" size={IconSize.MD} />
            <Box sx={{ ...visuallyHidden }}>{t('global:header:nav:ask_agent_icon_only')}</Box>
          </a>
        </Box>
      ),
      hamburger: (
        <MenuItem component={Link} to={'/aa/index.html'} target="__blank">
          <Icon folder="people/people-types/03-agent" size={IconSize.MD} />
          <Typography ml={2} variant="caption">
            {t('ns.global:header:nav:ask_agent')}
          </Typography>
        </MenuItem>
      ),
      requiredFeatureFlag: CU_FeatureFlags.ENABLE_ASK_AGENT,
      requiredUserFeatureFlag: CU_UserFeatureFlags.ASK_AGENT,
    },
    notifications: {
      // TODO - this is behind a feature flag, future work to enable notifications
      standard: (
        <Box height="100%" width="50px" display="flex" justifyContent="center" alignItems="center">
          🔔
        </Box>
      ),
      requiredFeatureFlag: CU_FeatureFlags.NOTIFICATIONS,
    },
  };
};

export interface NavbarLink {
  href: string;
  matchExact?: boolean;
  matchPath?: string;
  iconFolder: IconFolder;
  translation: string;
  requiredRoles?: Array<RoleSlug>;
  requiredFeatureFlag?: CU_FeatureFlags;
}

export const fullNavButtonLinks: Record<FullNavButton, NavbarLink> = {
  assistant: {
    href: '/app',
    matchExact: true,
    iconFolder: 'system/12-assistant',
    translation: 'global:header:nav:assistant',
    requiredRoles: [RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
  },
  tasks: {
    href: '/app/tasks',
    iconFolder: 'system/01-tasks',
    translation: 'global:header:nav:tasks',
    requiredRoles: AllRoles,
  },
  people: {
    href: '/app/people/users',
    matchPath: '/app/people',
    iconFolder: 'system/03-people',
    translation: 'global:header:nav:people',
    requiredRoles: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.SvcusrSpr],
  },
  assets: {
    href: '/app/assets',
    iconFolder: 'system/02-assets',
    translation: 'global:header:nav:assets',
    requiredRoles: AllRoles,
  },
  'performance-stats': {
    href: '/app/performance-stats',
    iconFolder: 'system/04-dashboard',
    translation: 'global:header:nav:dashboard',
    requiredRoles: [...ManagerRoles, RoleSlug.SvcusrSpr],
    requiredFeatureFlag: CU_FeatureFlags.PERF_STATS,
  },
};

export const menuOnlyNavButtonLinks: Record<MenuOnlyNavButton, NavbarLink> = {
  'admin-config': {
    href: '/app/admin-config',
    iconFolder: 'system/11-settings',
    translation: 'global:header:nav:admin_config',
    requiredRoles: [RoleSlug.ClientAdmin, RoleSlug.PlatAdmin],
    requiredFeatureFlag: CU_FeatureFlags.ADMIN_CONFIG,
  },
  exports: {
    href: '/app/exports',
    iconFolder: 'system/14-exports',
    translation: 'global:header:nav:exports',
    requiredRoles: AllRoles,
    requiredFeatureFlag: CU_FeatureFlags.ENABLE_EXPORT,
  },
};
