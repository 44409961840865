import { RoleSlug } from '@askporter/client-grieg-lyric';
import { ManagerRoles, TaskFilterPermissions } from '../../types';

// jira: AP-359
export const taskListFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  linkedAsset: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.Svcusr, RoleSlug.Supp, RoleSlug.SuppSpr],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SvcusrSpr],
  dueStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  statusType: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.Svcusr, RoleSlug.Supp, RoleSlug.SuppSpr],
  priority: [...ManagerRoles, RoleSlug.SvcusrSpr],
  respondStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  resolveStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  status: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.Svcusr, RoleSlug.Supp, RoleSlug.SuppSpr],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SvcusrSpr],
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282
export const teamLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: ManagerRoles,
  quickFilterRespondToday: ManagerRoles,
  quickFilterResolveToday: ManagerRoles,
  linkedAsset: ManagerRoles,
  assignedUser: ManagerRoles,
  assignedTeam: [],
  created: ManagerRoles,
  dueStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  statusType: ManagerRoles,
  priority: ManagerRoles,
  respondStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  resolveStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  status: ManagerRoles,
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: ManagerRoles,
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282
export const orgLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  linkedAsset: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.SuppSpr],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SvcusrSpr],
  dueStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  statusType: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.SuppSpr],
  priority: [...ManagerRoles, RoleSlug.SvcusrSpr],
  respondStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  resolveStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  status: [...ManagerRoles, RoleSlug.SvcusrSpr, RoleSlug.SuppSpr],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SvcusrSpr],
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282 - currently exactly the same as org
export const userLinkedTasksFilters = orgLinkedTasksFilters;

// jira: AP-1282
export const assetLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SvcusrSpr],
  linkedAsset: [],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SvcusrSpr],
  dueStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  statusType: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
  priority: [...ManagerRoles, RoleSlug.SvcusrSpr],
  respondStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  resolveStatus: [...ManagerRoles, RoleSlug.SvcusrSpr],
  status: [...ManagerRoles, RoleSlug.SuppSpr, RoleSlug.Supp, RoleSlug.Svcusr, RoleSlug.SvcusrSpr],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SvcusrSpr],
  extendedMetadata: ManagerRoles,
};
