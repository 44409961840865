import { SearchTaskSort } from '@askporter/client-grieg-lyric';

export const isRadioButtonChecked = (value: string, radioValue: string): boolean => {
  if (
    (value === SearchTaskSort.Recommended || value === SearchTaskSort.Relevance) &&
    radioValue === SearchTaskSort.Relevance
  ) {
    return true;
  } else {
    return value === radioValue;
  }
};
