import { TenantDisplayPalette } from '@askporter/client-grieg-lyric';

export const fallbackPalette: TenantDisplayPalette = {
  commonBlack: '#000',
  commonWhite: '#fff',
  primaryMain: '#1565c0ff',
  primaryLight: '#0d47a1ff',
  primaryDark: '#1976d2ff',
  primaryContrastText: '#ffffffff',
  secondaryMain: '#eeeeeeff',
  secondaryLight: '#f5f5f5ff',
  secondaryDark: '#e0e0e0ff',
  secondaryContrastText: '#212121ff',
  tertiaryMain: '#1565c0ff',
  tertiaryLight: '#1565c0ff',
  tertiaryDark: '#1565c0ff',
  tertiaryContrastText: '#1565c0ff',
  errorLight: '#cd6060ff',
  errorMain: '#be3333ff',
  errorDark: '#921616ff',
  errorContrastText: '#ffffffff',
  warningLight: '#ee854dff',
  warningMain: '#e9621aff',
  warningDark: '#a13900ff',
  warningContrastText: '#ffffffff',
  infoLight: '#1976d2ff',
  infoMain: '#1565c0ff',
  infoDark: '#0d47a1ff',
  infoContrastText: '#ffffffff',
  successLight: '#769e79ff',
  successMain: '#497e4dff',
  successDark: '#1b5e20ff',
  successContrastText: '#ffffffff',
  grey50: '#fafafaff',
  grey100: '#f5f5f5ff',
  grey200: '#eeeeeeff',
  grey300: '#e0e0e0ff',
  grey400: '#bdbdbdff',
  grey500: '#9e9e9eff',
  grey600: '#757575ff',
  grey700: '#616161ff',
  grey800: '#424242ff',
  grey900: '#212121ff',
  greyA100: '#f5f5f5ff',
  greyA200: '#eeeeeeff',
  greyA400: '#bdbdbdff',
  greyA700: '#616161ff',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  textPrimary: '#212121ff',
  textSecondary: '#616161ff',
  textDisabled: '#bdbdbdff',
  textHint: '#1565c0ff',
  divider: '#0000001F',
  backgroundPaper: '#ffffffff',
  backgroundDefault: '#f5f5f5ff',
  backgroundLevel2: '#f5f5f5ff',
  backgroundLevel1: '#ffffffff',
  actionActive: '#0000008A',
  actionHover: '#0000000A',
  actionHoverOpacity: 0.04,
  actionSelected: '#00000014',
  actionSelectedOpacity: 0.08,
  actionDisabled: '#00000042',
  actionDisabledBackground: '#0000001F',
  actionDisabledOpacity: 0.12,
  actionFocus: '#0000001F',
  actionFocusOpacity: 0.12,
  actionActivatedOpacity: 0.54,
};
