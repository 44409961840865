import React from 'react';
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps, FormLabel } from '@mui/material';

type AllowedVariants = 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'caption';
type AllowedComponents =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'p'
  | 'label'
  | 'article'
  | 'legend'
  | typeof FormLabel;
// export type CustomTypographyVariant = Exclude<MuiTypographyProps['variant'], 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'overline'>;
// export type CustomTypographyComponent = Exclude<MuiTypographyProps['component'], 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'overline'>;
export type CustomTypographyVariant = Extract<MuiTypographyProps['variant'], AllowedVariants>;
export type CustomTypographyComponent = Extract<MuiTypographyProps['component'], AllowedComponents> | typeof FormLabel;

export interface CustomTypographyProps extends Omit<MuiTypographyProps, 'variant' | 'component'> {
  variant?: CustomTypographyVariant;
  component?: CustomTypographyComponent;
}

export const Typography: React.FC<React.PropsWithChildren<CustomTypographyProps>> = (props) => {
  return <MuiTypography {...props} />;
};
